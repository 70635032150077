//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TipoCollaborazioneEnum { 
	Titolare = 0, 
	Associato = 1, 
	Dipendente = 2, 
	Collaboratore = 3
}
