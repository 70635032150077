<section>
  <div class="container-fluid">
    <div class="row full-height-vh">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="card  text-center width-400">
          <div class="card-img overlap">
            <img class="mb-1" src="assets/img/logos/logo-color-big.png" width="190">
          </div>
          <div class="card-body">
            <div class="card-block">
              <app-distributed-by></app-distributed-by>
              <app-validation-result [validationResult]="validationResult"></app-validation-result>
              <form novalidate (ngSubmit)="onSubmit()" #f="ngForm">
                <div class="form-group">
                  <div class="col-md-12">
                    <input type="text" class="form-control" name="inputNome" placeholder="Nome" [(ngModel)]="nome"
                      required #inputNome="ngModel">
                    <app-control-validation-result [control]="inputNome"></app-control-validation-result>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <input type="text" class="form-control" name="inputCognome" placeholder="Cognome"
                      [(ngModel)]="cognome" required #inputCognome="ngModel">
                    <app-control-validation-result [control]="inputCognome"></app-control-validation-result>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <input type="text" class="form-control" name="inputStudio" placeholder="Studio" [(ngModel)]="studio"
                      required #inputStudio="ngModel">
                    <app-control-validation-result [control]="inputStudio"></app-control-validation-result>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <select class="form-control" name="TipoCollaborazione" placeholder="Ruolo"
                      [(ngModel)]="tipoCollaborazione" required #inputTipocollaborazione="ngModel">
                      <!--  <option value="null" disabled selected hidden></option> -->
                      <option *ngFor="let s of tipiCollaborazione" [ngValue]="s.value">{{s.name}}</option>
                    </select>
                    <app-control-validation-result [control]="inputTipocollaborazione"></app-control-validation-result>
                  </div>
                </div>



                <div class="form-group">
                  <div class="col-md-12">
                    <input type="email" class="form-control" name="inputEmail" placeholder="Email" [(ngModel)]="email"
                      #inputEmail="ngModel" required email>
                    <app-control-validation-result [control]="inputEmail"></app-control-validation-result>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <input type="password" class="form-control" name="inputPass" placeholder="Password"
                      [(ngModel)]="password" #inputPass="ngModel" required minlength="8">
                    <app-control-validation-result [control]="inputPass"></app-control-validation-result>
                  </div>
                </div>
                <!-- <div class="form-group">
                  <div class="col-md-12">
                    <input type="checkbox" name="inputprivacy" #inputprivacy="ngModel" required [(ngModel)]="privacy">
                    Ho preso visione della
                    <a [routerLink]="['/privacypolicy']">Privacy Policy</a>
                    <app-control-validation-result [control]="inputprivacy"></app-control-validation-result>
                  </div>
                </div> -->
                <div class="form-group" *ngIf="recaptchaEnabled">
                  <div class="col-md-12">
                    <ngx-recaptcha2 [siteKey]="'6Lf6yMQUAAAAAG_0ShYx0mlW3Jc0P-wdgtmy0uut'" #captchaElem
                      [(ngModel)]="recaptchaToken" #inputRecaptchaToken="ngModel" name="recaptchaToken" [hl]="'it'"
                      required></ngx-recaptcha2>
                    <app-control-validation-result [control]="inputRecaptchaToken"></app-control-validation-result>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <button type="submit" class="btn btn-primary btn-block btn-raised" [disabled]="!f.valid"
                      [ladda]="isSubmitting">Registrati</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="card-footer">
            <div class="float-left">
              <a (click)="onForgotPassword()">Recupera Password</a>
            </div>
            <div class="float-right">
              <a (click)="onLogin()">Accedi</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>