import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-expander',
  templateUrl: './expander.component.html',
  styleUrls: ['./expander.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpanderComponent implements OnInit {
  @Input()
  isExpanded = false;

  @Input()
  canExpand = true;

  @Input()
  type = 'primary'

  constructor() { }

  ngOnInit() {
  }

  toggle() {
    if (!this.canExpand) {
      return;
    }

    this.isExpanded = !this.isExpanded
  }
}
