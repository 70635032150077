<fieldset [ngModelGroup]="source">
  <h4 class="form-section" *ngIf="legend">
    {{legend}}
  </h4>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label>Città</label>
        <input type="text" class="form-control" [(ngModel)]="indirizzo.Citta" name="{{namePreFix}}_{{rowNumer}}_Citta">
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label>Provincia</label>
        <input type="text" class="form-control" [(ngModel)]="indirizzo.Provincia"
          name="{{namePreFix}}_{{rowNumer}}_Provincia">
      </div>
    </div>
  </div>
</fieldset>