import { RecapitiDto } from './../../models/RecapitiDto';
import { OnInit } from '@angular/core';
import { Guid } from 'guid-typescript';
var RecapitiEditorComponent = /** @class */ (function () {
    function RecapitiEditorComponent() {
        this.rowNumer = 0;
        this.componentId = Guid.create();
        this._namePreFix = '';
    }
    Object.defineProperty(RecapitiEditorComponent.prototype, "namePreFix", {
        get: function () {
            return this._namePreFix + this.componentId;
        },
        set: function (namePreFix) {
            this._namePreFix = namePreFix;
        },
        enumerable: true,
        configurable: true
    });
    RecapitiEditorComponent.prototype.ngOnInit = function () {
    };
    return RecapitiEditorComponent;
}());
export { RecapitiEditorComponent };
