import { DatiDocumentoDto } from './../../models/DatiDocumentoDto';
import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { Guid } from 'guid-typescript';
import { ControlContainer, NgForm } from '@angular/forms';
import { TipoDocumentoOptions } from './dati-documento-options';

@Component({
  selector: 'app-dati-documento-editor',
  templateUrl: './dati-documento-editor.component.html',
  styleUrls: ['./dati-documento-editor.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatiDocumentoEditorComponent implements OnInit {
  @Input()
  legend: string;

  @Input()
  documento: DatiDocumentoDto;

  @Input()
  source: any;

  @Input()
  rowNumer = 0;

  TipoDocumentoOptions: string[];

  componentId = Guid.create();

  private _namePreFix = '';

  @Input()
  get namePreFix(): string {
    return this._namePreFix + this.componentId;
  }
  set namePreFix(namePreFix: string) {
    this._namePreFix = namePreFix;
  }

  constructor(private controlContainer: ControlContainer) { }

  ngOnInit() {
    this.TipoDocumentoOptions = TipoDocumentoOptions.sort((a, b) => (a > b ? 1 : -1));
  }

  onItemSelected(value: string) {
    (<NgForm>this.controlContainer).form.markAsDirty();
    this.documento.TipoDocumento = value;
  }
}
