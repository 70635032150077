<!-- <button class="btn btn-primary btn-raised btn-sm" (click)="openModal(content)">
  <i class="fa fa-search"></i>
</button> -->
<span (click)="openModal(content)" class="cursor-pointer success" title="Visualizza opzioni">
  <i class="fa fa-search fa-2x"></i>
</span>


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{modalTitle}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('close')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="useGroupedOptions">
      <div class="row">
        <div class="col-md-6" *ngFor="let go of groupedOptions">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th class="text-center">
                  {{go.GroupName}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="padding: 0px !important;" class="text-center">
                  <button class="btn btn-link" (click)="modal.close('')" style="margin-bottom: 0px ! important">
                    [BLANK]</button>
                </td>
              </tr>
              <tr *ngIf="showNoOption">
                <td style="padding: 0px !important;" class="text-center">
                  <button class="btn btn-link" (click)="modal.close('No')" style="margin-bottom: 0px ! important">
                    No</button>
                </td>
              </tr>
              <tr *ngFor="let opt of go.Options">
                <td style="padding: 0px !important;" class="text-center">
                  <button class="btn btn-link" (click)="modal.close(opt)" style="margin-bottom: 0px ! important">
                    {{opt}}</button>
                </td>
              </tr>
            </tbody>
            <!-- <tfoot *ngIf="go.Note">
              <tr>
                <td>{{go.Note}}</td>
              </tr>
            </tfoot> -->
          </table>

          <!-- <div *ngIf="go.Note">
            {{go.Note}}
          </div> -->

          <small class="text-justify" style="white-space: pre-line" *ngIf="go.Note">
            <i class="fa fa-info-circle"></i>
            {{go.Note}}
          </small>
        </div>
      </div>

    </ng-container>
    <ng-container *ngIf="!useGroupedOptions">
      <table class="table table-bordered table-striped">
        <tbody>
          <tr>
            <td style="padding: 0px !important;" [class]="columnClass">
              <button class="btn btn-link" (click)="modal.close('')" style="margin-bottom: 0px ! important">
                [BLANK]</button>
            </td>
          </tr>
          <tr *ngIf="showNoOption">
            <td style="padding: 0px !important;" [class]="columnClass">
              <button class="btn btn-link" (click)="modal.close('No')" style="margin-bottom: 0px ! important">
                No</button>
            </td>
          </tr>
          <tr *ngFor="let opt of options">
            <td style="padding: 0px !important;" [class]="columnClass">
              <ng-container *ngIf="multipleSelection">
                <input class="m-2" type="checkbox" [(checklist)]="selectedValues" [checklistValue]="opt"> {{opt}}
              </ng-container>

              <ng-container *ngIf="!multipleSelection">
                <button class="btn btn-link" (click)="modal.close(opt)" style="margin-bottom: 0px ! important">
                  {{opt}}</button>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
      <small class="text-justify" style="white-space: pre-line" *ngIf="note">
        <i class="fa fa-info-circle"></i>
        {{note}}
      </small>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success btn-sm btn-raised" (click)="modal.dismiss('save')">
      <span *ngIf="multipleSelection">Salva</span>
      <span *ngIf="!multipleSelection">Chiudi</span>
    </button>
  </div>
</ng-template>