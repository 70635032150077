import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DtoValidationResult } from 'app/models/DtoValidationResult';
import { UtenteDto } from 'app/models/UtenteDto';
import { AuthService } from 'app/shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-notifications-page',
  templateUrl: './notifications-page.component.html',
  styleUrls: ['./notifications-page.component.scss']
})
export class NotificationsPageComponent implements OnInit {
  validationResult = new DtoValidationResult();

  isSubmitting = false;
  user: UtenteDto;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.user = this.authService.getUser();
  }

  save(f: NgForm) {
    this.validationResult = null;

    if (!f.valid) {
      this.toastr.warning('Correggere gli errori e riprovare');
      return;
    }

    this.isSubmitting = true;

    this.authService.aggiornaProfilo(this.user).subscribe(response => {
      this.isSubmitting = false; 
      if (response.HasErrors) {
        this.validationResult = response.ValidationResult;
        this.toastr.warning('Salvataggio NON effettuato');
      } else {
        this.toastr.success('Salvataggio effettuato');
        f.form.markAsPristine();
        this.authService.setUser(this.user)
      }
    });
  }
}
