import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var LayoutService = /** @class */ (function () {
    function LayoutService() {
        this.emitChangeSource = new Subject();
        this.changeEmitted$ = this.emitChangeSource.asObservable();
    }
    LayoutService.prototype.emitChange = function (change) {
        this.emitChangeSource.next(change);
    };
    LayoutService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LayoutService_Factory() { return new LayoutService(); }, token: LayoutService, providedIn: "root" });
    return LayoutService;
}());
export { LayoutService };
