export enum SettingsPageViewModeEnum {
    DatiStudio = 1,
    Sicurezza = 2
}

export enum Av0PageViewModeEnum {
    Tutti = 0,
    TabellaA = 1,
    RischioInerente = 2,
    Vulnerabilita = 3,
    TabellaB = 4,
    RischioResiduo = 5,
    AzioniRischio = 6
}

export enum ClientiPageViewModeEnum {
    Standard = 0,
    Fascicolo = 1
}

export enum NovitaPageViewModeEnum {
    Aggiornamenti = 0,
    Archivio = 1
}
