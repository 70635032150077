import { ThemeService } from './shared/services/theme.service';
import { environment } from './../environments/environment.prod';
import { Component, ViewContainerRef, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { ThemeDto } from './models/ThemeDto';

@Component({
    selector: 'body',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    subscription: Subscription;

    theme: ThemeDto;

    @HostBinding('style.background-color')
    backgroundColor: string;

    constructor(private router: Router,
        private themeService: ThemeService,
        config: NgbDatepickerConfig,
        angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics) {

        this.theme = this.themeService.getCurrentTheme();
        this.backgroundColor = this.theme.bodyColor;

        if (environment.production) {
            angulartics2GoogleAnalytics.startTracking();
        }

        // customize default values of datepickers used by this component tree
        config.minDate = { year: 1900, month: 1, day: 1 };
        config.maxDate = { year: 2099, month: 12, day: 31 };

        // days that don't belong to current month are not visible
        config.outsideDays = 'hidden';
    }

    ngOnInit() {
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }



}