<section>
  <div class="container-fluid">
    <div class="row full-height-vh">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="card  text-center width-400">
          <div class="card-img overlap">
            <img class="mb-1" src="assets/img/logos/logo-color-big.png" width="190">
          </div>
          <div class="card-body">
            <div class="card-block">
              <app-distributed-by></app-distributed-by>
              <form novalidate (ngSubmit)="onSubmit()" #f="ngForm">
                <app-validation-result [validationResult]="validationResult"></app-validation-result>
                <div class="form-group">
                  <div class="col-md-12">
                    <input type="email" class="form-control" name="inputEmail" #inputEmail="ngModel" placeholder="Email"
                      [(ngModel)]="email" required email>
                    <app-control-validation-result [control]="inputEmail"></app-control-validation-result>
                  </div>
                </div>

                <div class="form-group" *ngIf="recaptchaEnabled">
                  <div class="col-md-12">
                    <ngx-recaptcha2 [siteKey]="'6Lf6yMQUAAAAAG_0ShYx0mlW3Jc0P-wdgtmy0uut'" #captchaElem
                      [(ngModel)]="recaptchaToken" #inputRecaptchaToken="ngModel" name="recaptchaToken" [hl]="'it'"
                      required></ngx-recaptcha2>
                    <app-control-validation-result [control]="inputRecaptchaToken"></app-control-validation-result>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <button type="submit" class="btn btn-primary btn-block btn-raised" [disabled]="!f.valid"
                      [ladda]="isSubmitting">Recupera password</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="card-footer">
            <div class="float-left">
              <a (click)="onLogin()">Accedi</a>
            </div>
            <div class="float-right">
              <a (click)="onRegister()">Registrati</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>