//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Dto } from './Dto';
import { DatiPersonaFisicaDto } from './DatiPersonaFisicaDto';
import { TipoCollaborazioneEnum } from './TipoCollaborazioneEnum';

export class CollaboratoreDto extends Dto
{
	constructor ()
	{
		super(); 
		this.DatiPersonaFisica = new DatiPersonaFisicaDto();
	}
	public DatiPersonaFisica: DatiPersonaFisicaDto;
	public TipoCollaborazione: TipoCollaborazioneEnum;
	public Professione: string;
	public AltriTitoli: string;
	public IscrittoOrdine: string;
	public NumeroSezione: string;
}
