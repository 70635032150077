<fieldset [ngModelGroup]="source">
  <h4 class="form-section" *ngIf="legend">
    {{legend}}
  </h4>
  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label>Denominazione</label>
        <input type="text" class="form-control" [(ngModel)]="studio.Denominazione"
          name="{{namePreFix}}_{{rowNumer}}_Via">
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>Codice fiscale</label>
        <input type="text" class="form-control" [(ngModel)]="studio.CodiceFiscale"
          name="{{namePreFix}}_{{rowNumer}}_CodiceFiscale">
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>Partita iva</label>
        <input type="text" class="form-control" [(ngModel)]="studio.PartitaIva"
          name="{{namePreFix}}_{{rowNumer}}_PartitaIva">
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label>Anno inizio attività</label>
        <input type="number" class="form-control" [(ngModel)]="studio.AnnoInizioAttivita"
          name="{{namePreFix}}_{{rowNumer}}_AnnoInizioAttivita">
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>Tipologia prevalente clientela</label>
        <textarea type="text" class="form-control" [(ngModel)]="studio.TipologiaPrevalenteClientela"
          name="{{namePreFix}}_{{rowNumer}}_TipologiaPrevalenteClientela"></textarea>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label>Principali prestazioni professionali</label>
        <textarea type="text" class="form-control" [(ngModel)]="studio.PrincipaliPrestazioniProfessionali"
          name="{{namePreFix}}_{{rowNumer}}_PrincipaliPrestazioniProfessionali"></textarea>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label>Specializzazioni</label>
        <textarea type="text" class="form-control" [(ngModel)]="studio.Specializzazioni"
          name="{{namePreFix}}_{{rowNumer}}_Specializzazioni"></textarea>
      </div>
    </div>



    <!-- <div class="col-md-4">
      <div class="form-group">
        <label>Numero professionisti</label>
        <input type="number" class="form-control" [(ngModel)]="studio.NumeroProfessionisti"
          name="{{namePreFix}}_{{rowNumer}}_NumeroProfessionisti">
      </div>
    </div> -->

    <!-- <div class="col-md-4">
      <div class="form-group">
        <label>Numero dipendenti e collaboratori</label>
        <input type="number" class="form-control" [(ngModel)]="studio.NumeroDipendentiCollaboratori"
          name="{{namePreFix}}_{{rowNumer}}_NumeroDipendentiCollaboratori">
      </div>
    </div> -->
  </div>
  <app-recapiti-editor [source]="studio.Recapiti" [recapiti]="studio.Recapiti" [namePreFix]="'Recapiti'"
    [legend]="'Recapiti'"></app-recapiti-editor>
  <app-indirizzo-editor [source]="studio.Indirizzo" [indirizzo]="studio.Indirizzo" [namePreFix]="'Indirizzo'"
    [legend]="'Indirizzo'"></app-indirizzo-editor>

  <h4>
    {{studio.Collaboratori.length}} Soggetti
    <button class="btn btn-primary btn-raised" (click)="addCollaboratore()">
      <i class="fa fa-plus-circle"></i>Aggiungi Soggetto</button>

    <button class="btn btn-link" (click)="openModal(modalFocusCessato)"><i class="fa fa-search fa-2x"></i>
      Focus</button>
    <ng-template #modalFocusCessato let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Focus</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-justify">
          In considerazione delle dimensioni e del grado di complessità organizzativa e operativa del soggetto
          obbligato, l’assetto organizzativo potrà prevedere una specifica funzione antiriciclaggio (con conseguente
          nomina del responsabile) come anche una funzione di revisione indipendente. Tali funzioni organizzative si
          strutturano e si dotano delle risorse necessarie.
        </div>
        <div class="text-justify mt-1">
          Le suddette funzioni, connesse, come già evidenziato, alle dimensioni del soggetto obbligato, sono individuate
          coerentemente ai parametri dimensionali indicati nella seguente tabella:
        </div>
        <table class="table table-bordered mb-0">
          <tbody>
            <tr>
              <td width="50%">Per 2 o più professionisti nello stesso studio (una sede o più) </td>
              <td width="50%">
                Occorre:
                <ul>
                  <li>introdurre la <strong>funzione antiriciclaggio</strong> e</li>
                  <li>nominare il relativo <strong>responsabile antiriciclaggio</strong></li>
                </ul>
              </td>
            </tr>
            <tr>
              <td width="50%">Per più di 30 professionisti e più di 30 collaboratori nello stesso studio (una sede o
                più) (1)</td>
              <td width="50%">
                Occorre:
                <ul>
                  <li>introdurre la <strong>funzione antiriciclaggio</strong> e</li>
                  <li>nominare il relativo <strong>responsabile antiriciclaggio</strong></li>
                  <li>introdurre una funzione di <strong>revisione indipendente</strong> per la verifica dei presidi di
                    controllo (interna o esterna)</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <small>
          (1) Tale soglia va individuata con riferimento al 31 dicembre dell’anno precedente.
        </small>
        <div class="text-justify mt-2">
          Si specifica che:
          <ul>
            <li>
              Per professionisti si intendono gli iscritti all’albo (individuali, associazioni, STP) con partita
              iva/codice fiscale autonomi;
            </li>
            <li>il numero dei collaboratori si individua in base alla definizione di ‘Personale; </li>
            <li>
              tra i collaboratori rientrano i tirocinanti e i titolari di contratti a progetto;
            </li>
            <li>
              l’eventuale condivisione di servizi e spazi fisici all’interno dei locali del soggetto obbligato, da parte
              di altri soggetti, non inseriti nell’ambito dell’organizzazione del personale, non ne determina
              l’inserimento ai fini del computo del numero dei professionisti;
            </li>
            <li>
              il numero delle sedi riguarda sia le unità nazionali sia quelle dislocate all’estero riferibili al
              soggetto obbligato;
            </li>
            <li>
              nel caso di associazione professionale o di STP è possibile creare un’unica funzione antiriciclaggio;
            </li>

            <li>
              nel caso di associazione professionale o STP nella cui compagine risultano anche soggetti non iscritti
              all’Albo dei dottori commercialisti e degli esperti contabili si dovrà comunque individuare una funzione
              antiriciclaggio in conformità alla normativa e alle regole tecniche emanate dal CNDCEC.
            </li>
          </ul>

          <div class="mt-2">
            Fonte: Regola Tecnica n° 1 e Linee guida.
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success btn-sm btn-raised"
          (click)="modal.close('Save click')">Chiudi</button>
      </div>
    </ng-template>
  </h4>
  <div class="m-3">
    <app-expander [type]="'info'" *ngFor="let te of studio.Collaboratori;let i=index;trackBy: trackByFn">
      <ng-container title>
        Soggetto {{i + 1}}

        <ng-container *ngIf="te.DatiPersonaFisica.Nome || te.DatiPersonaFisica.Cognome">
          - {{te.DatiPersonaFisica.Nome}} {{te.DatiPersonaFisica.Cognome}}
        </ng-container>

        <button class="btn btn-danger btn-raised" (click)="deleteCollaboratore(i)"
          *ngIf="studio.Collaboratori.length > 1">
          <i class="fa fa-minus-circle"></i>
          Elimina </button>
      </ng-container>
      <ng-container body>
        <app-collaboratore-editor [source]="te" [collaboratore]="te" [namePreFix]="'Collaboratore'" [rowNumer]="i">
        </app-collaboratore-editor>
      </ng-container>
    </app-expander>
  </div>
</fieldset>