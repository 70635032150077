import { Component, OnInit } from '@angular/core';
import { RecuperaPasswordDto } from 'app/models/RecuperaPasswordDto';
import { DtoValidationResult } from 'app/models/DtoValidationResult';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'app/shared/auth/auth.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss']
})
export class ForgotPasswordPageComponent implements OnInit {
  email = '';
  recaptchaToken = '';
  recaptchaEnabled = environment.production;
  isSubmitting = false;
  validationResult: DtoValidationResult;

  constructor(private router: Router,
    private toastr: ToastrService,
    private authService: AuthService) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.isSubmitting = true;
    const data = new RecuperaPasswordDto();
    data.Email = this.email;
    this.authService.passwordimenticata(data).subscribe(result => {
      this.isSubmitting = false;
      this.validationResult = result.ValidationResult;
      if (!result.HasErrors) {
        this.toastr.success('Email con il codice di reset password inviata')
        this.router.navigate(['resetpassword']);
      }
    });
  }

  onLogin() {
    this.router.navigate(['/login']);
  }

  onRegister() {
    this.router.navigate(['/register']);
  }
}
