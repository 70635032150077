import { DatiDocumentoDto } from './../../models/DatiDocumentoDto';
import { OnInit } from '@angular/core';
import { Guid } from 'guid-typescript';
import { ControlContainer, NgForm } from '@angular/forms';
import { TipoDocumentoOptions } from './dati-documento-options';
var DatiDocumentoEditorComponent = /** @class */ (function () {
    function DatiDocumentoEditorComponent(controlContainer) {
        this.controlContainer = controlContainer;
        this.rowNumer = 0;
        this.componentId = Guid.create();
        this._namePreFix = '';
    }
    Object.defineProperty(DatiDocumentoEditorComponent.prototype, "namePreFix", {
        get: function () {
            return this._namePreFix + this.componentId;
        },
        set: function (namePreFix) {
            this._namePreFix = namePreFix;
        },
        enumerable: true,
        configurable: true
    });
    DatiDocumentoEditorComponent.prototype.ngOnInit = function () {
        this.TipoDocumentoOptions = TipoDocumentoOptions.sort(function (a, b) { return (a > b ? 1 : -1); });
    };
    DatiDocumentoEditorComponent.prototype.onItemSelected = function (value) {
        this.controlContainer.form.markAsDirty();
        this.documento.TipoDocumento = value;
    };
    return DatiDocumentoEditorComponent;
}());
export { DatiDocumentoEditorComponent };
