//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { SessoEnum } from './SessoEnum';
import { IndirizzoBaseDto } from './IndirizzoBaseDto';
import { IndirizzoDto } from './IndirizzoDto';
import { RecapitiDto } from './RecapitiDto';
import { DatiDocumentoDto } from './DatiDocumentoDto';

export class DatiPersonaFisicaDto {
	constructor() {
		this.LuogoNascita = new IndirizzoBaseDto();
		this.Residenza = new IndirizzoDto();
		this.Domicilio = new IndirizzoDto();
		this.DatiDocumento = new DatiDocumentoDto();
		this.Recapiti = new RecapitiDto();
	}
	public Nome: string;
	public Cognome: string;
	public Sesso: SessoEnum;
	public LuogoNascita: IndirizzoBaseDto;
	public DataNascita: Date | null;
	public Nazionalita: string;
	public Cittadinanza: string;
	public Residenza: IndirizzoDto;
	public HaDomicilio: boolean;
	public Domicilio: IndirizzoDto;
	public CodiceFiscale: string;
	public PartitaIva: string;
	public Recapiti: RecapitiDto;
	public DatiDocumento: DatiDocumentoDto;
	public AttivitaSvolta: string;
	public Reddito: string;
	public DataInizioAttivita: Date | null;
	public AmbitoTerritorialeAttivita: string;
	public PoliticamenteEsposto: boolean;
	public PosizionePolitica: string;
	public FamigliarePoliticamenteEsposto: boolean;
	public FamigliarePosizionePolitica: string;
	public ContiguoPoliticamenteEsposto: boolean;
	public ContiguoPosizionePolitica: string;
	public ProcedimentiRiciclaggio: boolean;
	public IndaginiRiciclaggio: boolean;
	public CollegamentiSoggettiProcedimenti: boolean;
	public CollegamentiSoggetti: boolean;
	public OperazioniContropartiPaesiAltoRischio: boolean;
	public NotePep: string;
}
