/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./full-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../shared/navbar/navbar.component.ngfactory";
import * as i4 from "../../shared/navbar/navbar.component";
import * as i5 from "../../shared/auth/auth.service";
import * as i6 from "../../shared/services/rivenditore.service";
import * as i7 from "@angular/router";
import * as i8 from "../../shared/services/layout.service";
import * as i9 from "../../shared/services/config.service";
import * as i10 from "../../shared/footer/footer.component.ngfactory";
import * as i11 from "../../shared/footer/footer.component";
import * as i12 from "./full-layout.component";
var styles_FullLayoutComponent = [i0.styles];
var RenderType_FullLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FullLayoutComponent, data: {} });
export { RenderType_FullLayoutComponent as RenderType_FullLayoutComponent };
export function View_FullLayoutComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { sidebarBgImage: 0 }), i1.ɵqud(671088640, 2, { appSidebar: 0 }), i1.ɵqud(671088640, 3, { wrapper: 0 }), (_l()(), i1.ɵeld(3, 0, [[3, 0], ["wrapper", 1]], null, 13, "div", [["class", "wrapper"]], [[8, "dir", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "nav-collapsed": 0, "menu-collapsed": 1, "sidebar-sm": 2, "sidebar-lg": 3 }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-navbar", [], null, [[null, "toggleHideSidebar"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("toggleHideSidebar" === en)) {
        var pd_0 = (_co.toggleHideSidebar($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_NavbarComponent_0, i3.RenderType_NavbarComponent)), i1.ɵdid(8, 4308992, null, 0, i4.NavbarComponent, [i5.AuthService, i6.RivenditoreService, i7.Router, i8.LayoutService, i9.ConfigService], null, { toggleHideSidebar: "toggleHideSidebar" }), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [["class", "main-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "main-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(14, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 1, "app-footer", [], null, null, null, i10.View_FooterComponent_0, i10.RenderType_FooterComponent)), i1.ɵdid(16, 49152, null, 0, i11.FooterComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "wrapper"; var currVal_2 = _ck(_v, 6, 0, _co.iscollapsed, _co.iscollapsed, _co.isSidebar_sm, _co.isSidebar_lg); _ck(_v, 5, 0, currVal_1, currVal_2); _ck(_v, 8, 0); _ck(_v, 14, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options.direction; _ck(_v, 3, 0, currVal_0); }); }
export function View_FullLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-full-layout", [], null, null, null, View_FullLayoutComponent_0, RenderType_FullLayoutComponent)), i1.ɵdid(1, 4308992, null, 0, i12.FullLayoutComponent, [i1.ElementRef, i9.ConfigService, i2.DOCUMENT, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FullLayoutComponentNgFactory = i1.ɵccf("app-full-layout", i12.FullLayoutComponent, View_FullLayoutComponent_Host_0, {}, {}, []);
export { FullLayoutComponentNgFactory as FullLayoutComponentNgFactory };
