import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { DtoValidationResult } from 'app/models/DtoValidationResult';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { IdRivenditori } from 'app/models/IdRivenditori';
import { RegistraUtenteDto } from 'app/models/RegistraUtenteDto';
import { EnumExtension } from 'app/shared/extensions/enumExtension';
import { TipoCollaborazioneEnum } from 'app/models/TipoCollaborazioneEnum';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})
export class RegisterPageComponent implements OnInit {
  validationResult: DtoValidationResult;
  isSubmitting: boolean;

  idRivenditore: string;
  nome: string;
  cognome: string;
  studio: string;
  email: string;
  password: string;
  privacy: boolean;
  recaptchaToken = '';
  recaptchaEnabled = environment.production;
  tipiCollaborazione: { name: string; value: number; }[];
  tipoCollaborazione: TipoCollaborazioneEnum;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.tipiCollaborazione = EnumExtension.getNamesAndValues(TipoCollaborazioneEnum).filter(x => x.value <= 1);
    const ref = this.route.queryParams['ref'];
    if (ref) {
      this.idRivenditore = ref;
    } else {
      this.idRivenditore = IdRivenditori.EnterpriseRivenditoreId;
    }
  }

  onSubmit() {
    this.isSubmitting = true;
    const data = new RegistraUtenteDto();
    data.Nome = this.nome;
    data.Cognome = this.cognome;
    data.Studio = this.studio;
    data.Email = this.email;
    data.Password = this.password;
    data.TipoCollaborazione = this.tipoCollaborazione;
    data.IdRivenditore = this.idRivenditore;

    this.authService.registra(data).subscribe(result => {
      this.isSubmitting = false;

      if (result.HasErrors) {
        this.validationResult = result.ValidationResult;
      } else {
        this.toastr.success('Registrazione effettuata');
        this.router.navigate(['/login']);
      }
    });
  }

  onLogin() {
    this.router.navigate(['/login']);
  }

  onForgotPassword() {
    this.router.navigate(['/forgotpassword']);
  }
}
