import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { PrivacyPolicyPageComponent } from './pages/privacy-policy-page/privacy-policy-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { SettingsPageComponent } from './pages/settings-page/settings-page.component';
import { LicensePageComponent } from './pages/license-page/license-page.component';
import { SettingsPageViewModeEnum } from './models/ViewModeEnum';
import { CustomizePageComponent } from './pages/customize-page/customize-page.component';
import { NotificationsPageComponent } from './pages/notifications-page/notifications-page.component';


const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('../app/pages/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'clienti',
        loadChildren: () => import('./pages/clienti/clienti.module').then(m => m.ClientiModule)
      },
      {
        path: 'av0',
        loadChildren: () => import('./pages/av0/av0.module').then(m => m.Av0Module)
      },
      {
        path: 'av1',
        loadChildren: () => import('./pages/av1/av1.module').then(m => m.Av1Module)
      },
      {
        path: 'av2',
        loadChildren: () => import('./pages/av2/av2.module').then(m => m.Av2Module)
      },
      {
        path: 'av4',
        loadChildren: () => import('./pages/av4/av4.module').then(m => m.Av4Module)
      },
      {
        path: 'av5',
        loadChildren: () => import('./pages/av5/av5.module').then(m => m.Av5Module)
      },
      {
        path: 'av6',
        loadChildren: () => import('./pages/av6/av6.module').then(m => m.Av6Module)
      },
      {
        path: 'av7',
        loadChildren: () => import('./pages/av7/av7.module').then(m => m.Av7Module)
      },
      {
        path: 'scadenziario',
        loadChildren: () => import('./pages/scadenziario/scadenziario.module').then(m => m.ScadenziarioModule)
      },
      {
        path: 'verificatitolareeffettivo',
        loadChildren: () => import('./pages/verifica-titolare-effettivo/verifica-titolare-effettivo.module').then(m => m.VerificaTitolareEffettivoModule)
      },
      {
        path: 'rivenditori',
        loadChildren: () => import('../app/pages/rivenditori/rivenditori.module').then(m => m.RivenditoriModule)
      },
      {
        path: 'utenti',
        loadChildren: () => import('../app/pages/utenti/utenti.module').then(m => m.UtentiModule)
      },
      {
        path: 'news',
        loadChildren: () => import('../app/pages/news/news.module').then(m => m.NewsModule)
      },
      {
        path: 'licenze',
        loadChildren: './pages/licenze/licenze.module#LicenzeModule'
      },
      {
        path: 'updates',
        loadChildren: './pages/update/update.module#UpdateModule'
      },
      {
        path: 'tipilicenze',
        loadChildren: './pages/tipi-licenze/tipi-licenze.module#TipiLicenzeModule'
      },
      // {
      //   path: 'titolareeffettivo',
      //   component: TitolareEffettivoComponent
      // },
      {
        path: 'datistudio',
        component: SettingsPageComponent,
        data: {
          viewmode: SettingsPageViewModeEnum.DatiStudio,
        }
      },
      {
        path: 'sicurezza',
        component: SettingsPageComponent,
        data: {
          viewmode: SettingsPageViewModeEnum.Sicurezza,
        }
      },
      {
        path: 'license',
        component: LicensePageComponent
      },
      {
        path: 'customize',
        component: CustomizePageComponent
      },
      {
        path: 'notifications',
        component: NotificationsPageComponent
      }
    ], canActivate: [AuthGuard], canActivateChild: [AuthGuard]
  },
  // { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [AuthGuard] },
  // { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES, canActivate: [AuthGuard] },
  { path: 'login', component: LoginPageComponent },
  { path: 'privacypolicy', component: PrivacyPolicyPageComponent },
  { path: 'register', component: RegisterPageComponent },
  { path: 'forgotpassword', component: ForgotPasswordPageComponent },
  { path: 'resetpassword', component: ResetPasswordPageComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: '**', component: NotFoundPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
