<!-- {{persona | json}} -->
<fieldset [ngModelGroup]="source">
  <h4 class="form-section" *ngIf="legend">
    {{legend}}
  </h4>
  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <label>Nome</label>
        <input type="text" class="form-control" [(ngModel)]="persona.Nome" name="{{namePreFix}}_{{rowNumer}}_Nome">
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>Cognome</label>
        <input type="text" class="form-control" [(ngModel)]="persona.Cognome"
          name="{{namePreFix}}_{{rowNumer}}_Cognome">
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>Sesso</label>
        <select class="form-control" [(ngModel)]="persona.Sesso" name="{{namePreFix}}_{{rowNumer}}_Sesso">
          <option *ngFor="let s of sesso" [ngValue]="s.value">{{s.name}}</option>
        </select>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>Data di nascita</label>
        <div class="input-group">
          <input type="text" class="form-control" name="{{namePreFix}}_{{rowNumer}}_DataNascita"
            [(ngModel)]="persona.DataNascita" ngbDatepicker #d1="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="d1.toggle()">
              <i class="fa fa-calendar"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-indirizzo-base-editor [source]="persona.LuogoNascita" [indirizzo]="persona.LuogoNascita"
    [namePreFix]="'LuogoNascita'" [legend]="'Luogo di Nascita'"></app-indirizzo-base-editor>
  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <label>Codice fiscale</label>
        <input type="text" class="form-control" name="{{namePreFix}}_{{rowNumer}}_CodiceFiscale"
          [(ngModel)]="persona.CodiceFiscale">
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>Partita iva</label>
        <input type="text" class="form-control" name="{{namePreFix}}_{{rowNumer}}_PartitaIva"
          [(ngModel)]="persona.PartitaIva">
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>Nazionalità</label>
        <input type="text" class="form-control" name="{{namePreFix}}_{{rowNumer}}_Nazionalita"
          [(ngModel)]="persona.Nazionalita">
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>Cittadinanza</label>
        <input type="text" class="form-control" name="{{namePreFix}}_{{rowNumer}}_Cittadinanza"
          [(ngModel)]="persona.Cittadinanza">
      </div>
    </div>
  </div>

  <app-indirizzo-editor [source]="persona.Residenza" [indirizzo]="persona.Residenza" [namePreFix]="'Residenza'"
    [legend]="'Residenza'"></app-indirizzo-editor>

  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <input type="checkbox" [(ngModel)]="persona.HaDomicilio" name="{{namePreFix}}_{{rowNumer}}_HaDomicilio">&nbsp;
        Domicilio diverso rispetto
        alla
        residenza del documento di identificazione allegato
      </div>
    </div>
  </div>
  <app-indirizzo-editor [source]="persona.Domicilio" [indirizzo]="persona.Domicilio" [namePreFix]="'Domicilio'"
    [legend]="'Domicilio'" *ngIf="persona.HaDomicilio"></app-indirizzo-editor>
  <app-recapiti-editor [source]="persona.Recapiti" [recapiti]="persona.Recapiti" [namePreFix]="'Recapiti'"
    [legend]="'Recapiti'" *ngIf="mostraRecapiti"></app-recapiti-editor>

  <app-dati-documento-editor [source]="persona.DatiDocumento" [documento]="persona.DatiDocumento"
    [namePreFix]="'DatiDocumento'" [legend]="'Dati documento'"></app-dati-documento-editor>

  <div class="row">
    <div [ngClass]="{'col-md-9': mostraReddito, 'col-md-12': !mostraReddito}">
      <div class="form-group">
        <label>Attività svolta</label>
        <textarea type="text" class="form-control" [(ngModel)]="persona.AttivitaSvolta"
          name="{{namePreFix}}_{{rowNumer}}_AttivitaSvolta"></textarea>
      </div>
    </div>
    <div class="col-md-3" *ngIf="mostraReddito">
      <div class="form-group">
        <label>Reddito
          <app-options-modal [options]="RedditoOptions" [modalTitle]="'Reddito'"
            (itemSelected)="onRedditoSelected($event)">
          </app-options-modal>
        </label>
        <textarea type="text" class="form-control" [(ngModel)]="persona.Reddito"
          name="{{namePreFix}}_{{rowNumer}}_Reddito"></textarea>
      </div>
    </div>
    <div class="col-md-9" *ngIf="mostraAmbitoTerritorialeAttivita">
      <div class="form-group">
        <label>Ambito territoriale attività</label>
        <textarea type="text" class="form-control" [(ngModel)]="persona.AmbitoTerritorialeAttivita"
          name="{{namePreFix}}_{{rowNumer}}_AmbitoTerritorialeAttivita"></textarea>
      </div>
    </div>
    <div class="col-md-3" *ngIf="mostraDataInizioAttivita">
      <div class="form-group">
        <label>Data inizio attività</label>
        <div class="input-group">
          <input type="text" class="form-control" name="{{namePreFix}}_{{rowNumer}}_DataInizioAttivita"
            [(ngModel)]="persona.DataInizioAttivita" ngbDatepicker #d2="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="d2.toggle()">
              <i class="fa fa-calendar"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4" *ngIf="mostraPep">
      <div class="form-group">
        <label>
          <input type="checkbox" [(ngModel)]="persona.PoliticamenteEsposto"
            name="{{namePreFix}}_{{rowNumer}}_PoliticamenteEsposto">
          Politicamente esposto</label>
        <label>
          <app-options-modal [options]="PPEOptions" [modalTitle]="'Politicamente esposto'"
            (itemSelected)="onPosizionePoliticaSelected($event)">
          </app-options-modal>
          <!-- <app-help-modal [modalBody]="pepHelpText" [modalTitle]="'Politicamente esposto'">
          </app-help-modal> -->
        </label>
        <textarea class="form-control" [(ngModel)]="persona.PosizionePolitica"
          name="{{namePreFix}}_{{rowNumer}}_PosizionePolitica" *ngIf="persona.PoliticamenteEsposto"
          placeholder="Posizione" rows="1"></textarea>
      </div>
    </div>
    <div class="col-md-4" *ngIf="mostraPep">
      <div class="form-group">
        <label>
          <input type="checkbox" [(ngModel)]="persona.FamigliarePoliticamenteEsposto"
            name="{{namePreFix}}_{{rowNumer}}_FamigliarePoliticamenteEsposto">
          Famigliare politicamente esposto</label>
        <label>
          <!-- <app-help-modal [modalBody]="pepHelpText" [modalTitle]="'Famigliare politicamente esposto'">
          </app-help-modal> -->
          <app-options-modal [options]="PPEOptions" [modalTitle]="'Famigliare politicamente esposto'"
            (itemSelected)="onFamigliarePosizionePoliticaSelected($event)">
          </app-options-modal>
        </label>
        <textarea type="text" class="form-control" [(ngModel)]="persona.FamigliarePosizionePolitica"
          name="{{namePreFix}}_{{rowNumer}}_FamigliarePosizionePolitica" *ngIf="persona.FamigliarePoliticamenteEsposto"
          placeholder="Posizione" rows="1"></textarea>
      </div>
    </div>
    <div class="col-md-4" *ngIf="mostraPep">
      <div class="form-group">
        <label>
          <input type="checkbox" [(ngModel)]="persona.ContiguoPoliticamenteEsposto"
            name="{{namePreFix}}_{{rowNumer}}_ContiguoPoliticamenteEsposto">
          Ha stretti legami con PPE</label>

        <label>
          <!-- <app-help-modal [modalBody]="pepHelpText"
            [modalTitle]="'Ha stretti legami con persona politicamente esposta'">
          </app-help-modal> -->
          <app-options-modal [options]="PPEOptions" [modalTitle]="'Ha stretti legami con persona politicamente esposta'"
            (itemSelected)="onContiguoPosizionePoliticaSelected($event)">
          </app-options-modal>
        </label>
        <textarea type="text" class="form-control" [(ngModel)]="persona.ContiguoPosizionePolitica"
          name="{{namePreFix}}_{{rowNumer}}_ContiguoPosizionePolitica" *ngIf="persona.ContiguoPoliticamenteEsposto"
          placeholder="Posizione" rows="1"></textarea>
      </div>
    </div>

    <div class="col-md-12" *ngIf="mostraPep">
      <div class="form-group">
        <label>
          <input type="checkbox" [(ngModel)]="persona.ProcedimentiRiciclaggio"
            name="{{namePreFix}}_{{rowNumer}}_ProcedimentiRiciclaggio">
          Procedimenti pregressi per reati legati al riciclaggio o al finanziamento del terrorismo </label>
      </div>

      <div class="form-group">
        <label>
          <input type="checkbox" [(ngModel)]="persona.IndaginiRiciclaggio"
            name="{{namePreFix}}_{{rowNumer}}_IndaginiRiciclaggio">
          Indagini in corso a proprio carico per reati legati al riciclaggio o al finanziamento del terrorismo</label>
      </div>

      <div class="form-group">
        <label>
          <input type="checkbox" [(ngModel)]="persona.CollegamentiSoggettiProcedimenti"
            name="{{namePreFix}}_{{rowNumer}}_CollegamentiSoggettiProcedimenti">
          Collegamenti diretti o indiretti o di operare per conto di soggetti sottoposti a procedimenti penali o a
          misure di prevenzione patrimoniale o ad altri provvedimenti di sequestro </label>
      </div>

      <div class="form-group">
        <label>
          <input type="checkbox" [(ngModel)]="persona.CollegamentiSoggetti"
            name="{{namePreFix}}_{{rowNumer}}_CollegamentiSoggetti">
          Collegamenti diretti o indiretti o di operare per conto di persone politicamente esposte o con soggetti
          censiti nelle liste pubbliche delle persone o degli enti coinvolti nel finanziamento del terrorismo</label>
      </div>

      <div class="form-group">
        <label>
          <input type="checkbox" [(ngModel)]="persona.OperazioniContropartiPaesiAltoRischio"
            name="{{namePreFix}}_{{rowNumer}}_OperazioniContropartiPaesiAltoRischio">
          Operazioni con controparti situate in uno dei Paesi o territori ad alto rischio </label>
      </div>


      <div class="form-group">
        <label>Note</label>
        <textarea type="text" class="form-control" [(ngModel)]="persona.NotePep"
          name="{{namePreFix}}_{{rowNumer}}_NotePep"></textarea>
      </div>

    </div>
  </div>
</fieldset>