/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./options-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../directives/checklist.directive";
import * as i4 from "./options-modal.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
var styles_OptionsModalComponent = [i0.styles];
var RenderType_OptionsModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OptionsModalComponent, data: {} });
export { RenderType_OptionsModalComponent as RenderType_OptionsModalComponent };
function View_OptionsModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "td", [["class", "text-center"], ["style", "padding: 0px !important;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "btn btn-link"], ["style", "margin-bottom: 0px ! important"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.parent.parent.parent.context.$implicit.close("No") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" No"]))], null, null); }
function View_OptionsModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "td", [["class", "text-center"], ["style", "padding: 0px !important;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "btn btn-link"], ["style", "margin-bottom: 0px ! important"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.parent.parent.parent.context.$implicit.close(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, [" ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 3, 0, currVal_0); }); }
function View_OptionsModalComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "small", [["class", "text-justify"], ["style", "white-space: pre-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-info-circle"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.Note; _ck(_v, 2, 0, currVal_0); }); }
function View_OptionsModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "table", [["class", "table table-bordered table-striped"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 8, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "td", [["class", "text-center"], ["style", "padding: 0px !important;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-link"], ["style", "margin-bottom: 0px ! important"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.parent.parent.context.$implicit.close("") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" [BLANK]"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OptionsModalComponent_4)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OptionsModalComponent_5)), i1.ɵdid(14, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OptionsModalComponent_6)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showNoOption; _ck(_v, 12, 0, currVal_1); var currVal_2 = _v.context.$implicit.Options; _ck(_v, 14, 0, currVal_2); var currVal_3 = _v.context.$implicit.Note; _ck(_v, 16, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.GroupName; _ck(_v, 5, 0, currVal_0); }); }
function View_OptionsModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OptionsModalComponent_3)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.groupedOptions; _ck(_v, 3, 0, currVal_0); }, null); }
function View_OptionsModalComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "td", [["style", "padding: 0px !important;"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "btn btn-link"], ["style", "margin-bottom: 0px ! important"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.parent.parent.context.$implicit.close("No") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" No"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columnClass; _ck(_v, 1, 0, currVal_0); }); }
function View_OptionsModalComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "input", [["class", "m-2"], ["type", "checkbox"]], [[8, "checked", 0]], [[null, "checklistChange"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).triggerOnChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("checklistChange" === en)) {
        var pd_1 = ((_co.selectedValues = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 540672, null, 0, i3.ChecklistDirective, [], { checklist: [0, "checklist"], checklistValue: [1, "checklistValue"] }, { checklistChange: "checklistChange" }), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.selectedValues; var currVal_2 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).isChecked; _ck(_v, 1, 0, currVal_0); var currVal_3 = _v.parent.context.$implicit; _ck(_v, 3, 0, currVal_3); }); }
function View_OptionsModalComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-link"], ["style", "margin-bottom: 0px ! important"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.parent.parent.parent.context.$implicit.close(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_OptionsModalComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "td", [["style", "padding: 0px !important;"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OptionsModalComponent_10)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OptionsModalComponent_11)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.multipleSelection; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.multipleSelection; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columnClass; _ck(_v, 1, 0, currVal_0); }); }
function View_OptionsModalComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "small", [["class", "text-justify"], ["style", "white-space: pre-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-info-circle"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.note; _ck(_v, 2, 0, currVal_0); }); }
function View_OptionsModalComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "table", [["class", "table table-bordered table-striped"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "td", [["style", "padding: 0px !important;"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-link"], ["style", "margin-bottom: 0px ! important"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.parent.context.$implicit.close("") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" [BLANK]"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OptionsModalComponent_8)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OptionsModalComponent_9)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OptionsModalComponent_12)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showNoOption; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.options; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.note; _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columnClass; _ck(_v, 4, 0, currVal_0); }); }
function View_OptionsModalComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Salva"]))], null, null); }
function View_OptionsModalComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Chiudi"]))], null, null); }
function View_OptionsModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.dismiss("close") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OptionsModalComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OptionsModalComponent_7)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "button", [["class", "btn btn-success btn-sm btn-raised"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.dismiss("save") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OptionsModalComponent_13)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OptionsModalComponent_14)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.useGroupedOptions; _ck(_v, 8, 0, currVal_1); var currVal_2 = !_co.useGroupedOptions; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.multipleSelection; _ck(_v, 14, 0, currVal_3); var currVal_4 = !_co.multipleSelection; _ck(_v, 16, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalTitle; _ck(_v, 2, 0, currVal_0); }); }
export function View_OptionsModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "cursor-pointer success"], ["title", "Visualizza opzioni"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModal(i1.ɵnov(_v, 2)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-search fa-2x"]], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["content", 2]], null, 0, null, View_OptionsModalComponent_1))], null, null); }
export function View_OptionsModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-options-modal", [], null, null, null, View_OptionsModalComponent_0, RenderType_OptionsModalComponent)), i1.ɵdid(1, 114688, null, 0, i4.OptionsModalComponent, [i5.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OptionsModalComponentNgFactory = i1.ɵccf("app-options-modal", i4.OptionsModalComponent, View_OptionsModalComponent_Host_0, { modalTitle: "modalTitle", multipleSelection: "multipleSelection", useGroupedOptions: "useGroupedOptions", options: "options", groupedOptions: "groupedOptions", showNoOption: "showNoOption", note: "note", columnClass: "columnClass" }, { itemSelected: "itemSelected" }, []);
export { OptionsModalComponentNgFactory as OptionsModalComponentNgFactory };
