//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import {Guid} from 'guid-typescript';

export abstract class Dto
{
	constructor ()
	{
		
		this.Id = Guid.create().toString();
	}
	public Id: string;
}
