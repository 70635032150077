import { Component, OnInit, Input } from '@angular/core';
import { NgForm, ValidationErrors, FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-form-validation-result',
  templateUrl: './form-validation-result.component.html',
  styleUrls: ['./form-validation-result.component.scss']
})
export class FormValidationResultComponent implements OnInit {
  @Input()
  form: NgForm;

  constructor() { }

  ngOnInit() {
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any; } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors = (control instanceof FormGroup || control instanceof FormArray)
        ? this.getAllErrors(control)
        : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any; });
    return hasError ? result : null;
  }
}
