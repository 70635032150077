import { TipoCollaborazioneEnum } from './../../models/TipoCollaborazioneEnum';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { TitolareEffettivoDto } from 'app/models/TitolareEffettivoDto';
import { Guid } from 'guid-typescript';
import { EnumExtension } from '../extensions/enumExtension';
import { CollaboratoreDto } from 'app/models/CollaboratoreDto';

@Component({
  selector: 'app-collaboratore-editor',
  templateUrl: './collaboratore-editor.component.html',
  styleUrls: ['./collaboratore-editor.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollaboratoreEditorComponent implements OnInit {
  @Input()
  legend: string;

  @Input()
  collaboratore: CollaboratoreDto;

  @Input()
  source: any;

  @Input()
  rowNumer = 0;

  @Input()
  showRuolo = true;

  tipoCollaborazione: { name: string; value: number; }[];

  componentId = Guid.create();

  private _namePreFix = '';

  @Input()
  get namePreFix(): string {
    return this._namePreFix + this.componentId;
  }
  set namePreFix(namePreFix: string) {
    this._namePreFix = namePreFix;
  }

  constructor() {
    this.tipoCollaborazione = EnumExtension.getNamesAndValues(TipoCollaborazioneEnum);
  }

  ngOnInit() {

  }

}
