import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ApplicationRoles } from 'app/models/ApplicationRoles';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAuth(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAuth(route, state);
  }

  private checkAuth(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isAuthenticated = this.authService.isAuthenticated();
    if (!isAuthenticated) {
      this.router.navigate(['login']);
      return false;
    }

    if (state.url !== '/license') {
      const userLicense = this.authService.getUser().Licenza;
      const isAdmin = this.authService.isInRole([ApplicationRoles.RuoloAdmin, ApplicationRoles.RuoloPowerUser]);
      if ((userLicense.IsDemo) && !isAdmin) {
        this.router.navigate(['license']);
        return false;
      }
    }


    const roles = route.data.roles as Array<string>;
    if (roles && roles.length > 0) {
      const isInRole = this.authService.isInRole(roles);
      if (!isInRole) {
        this.router.navigate(['login']);
        return false;
      }
    }

    return true;
  }
}
