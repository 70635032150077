import { IndirizzoDto } from './../../models/IndirizzoDto';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-indirizzo-editor',
  templateUrl: './indirizzo-editor.component.html',
  styleUrls: ['./indirizzo-editor.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndirizzoEditorComponent implements OnInit {
  @Input()
  legend: string;

  @Input()
  indirizzo: IndirizzoDto;

  @Input()
  source: any;

  @Input()
  rowNumer = 0;

  componentId = Guid.create();

  private _namePreFix = '';

  @Input()
  get namePreFix(): string {
    return this._namePreFix + this.componentId;
  }
  set namePreFix(namePreFix: string) {
    this._namePreFix = namePreFix;
  }

  constructor() { }

  ngOnInit() {
  }
}
