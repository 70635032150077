<fieldset [ngModelGroup]="source">
  <h4 class="form-section" *ngIf="legend">
    {{legend}}
  </h4>
  <div class="row" *ngIf="showRuolo">
    <div class="col-md-3">
      <div class="form-group">
        <label>Ruolo</label>
        <select class="form-control" [(ngModel)]="collaboratore.TipoCollaborazione"
          name="{{namePreFix}}_{{rowNumer}}_TipoCollaborazione">
          <!--  <option value="null" disabled selected hidden></option> -->
          <option *ngFor="let s of tipoCollaborazione" [ngValue]="s.value">{{s.name}}</option>
        </select>
      </div>
    </div>
  </div>

  <app-dati-persona-fisica-editor [source]="collaboratore.DatiPersonaFisica" [persona]="collaboratore.DatiPersonaFisica"
    [namePreFix]="namePreFix" [mostraPep]="false">
  </app-dati-persona-fisica-editor>
  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <label>Professione</label>
        <textarea class="form-control" [(ngModel)]="collaboratore.Professione"
          name="{{namePreFix}}_{{rowNumer}}_Professione" rows="1"></textarea>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>Altri titoli</label>
        <textarea class="form-control" [(ngModel)]="collaboratore.AltriTitoli"
          name="{{namePreFix}}_{{rowNumer}}_AltriTitoli" rows="1"></textarea>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>Iscritto all'ordine di</label>
        <input class="form-control" [(ngModel)]="collaboratore.IscrittoOrdine"
          name="{{namePreFix}}_{{rowNumer}}_IscrittoOrdine">
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>Numero/sezione</label>
        <input class="form-control" [(ngModel)]="collaboratore.NumeroSezione"
          name="{{namePreFix}}_{{rowNumer}}_NumeroSezione">
      </div>
    </div>
  </div>
</fieldset>