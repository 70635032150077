import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { PagedList } from 'app/models/PagedList';
import { HttpParamsExtension } from '../extensions/httpParamsExtension';
import { OperationResult } from 'app/models/OperationResult';
import { HttpClient } from '@angular/common/http';
import { RivenditoreDto } from 'app/models/RivenditoreDto';

@Injectable({
  providedIn: 'root'
})
export class RivenditoreService {

  constructor(private http: HttpClient) { }

  getLogo(id: string): string {
    // const authToken = this.authService.getToken().access_token;
    return `${environment.baseApiUrl}/rivenditore/getlogo/${id}`;
  }

  list(page: number, pageSize: number = 10, filter: string = ''): Observable<PagedList<RivenditoreDto>> {
    const httpParams = HttpParamsExtension.ToHttpParams({
      page: page,
      pageSize: pageSize,
      filter: filter
    });

    return this.http
      .get<PagedList<RivenditoreDto>>('/rivenditore/get', { params: httpParams });
  };

  getNuovo(): Observable<RivenditoreDto> {
    return this.http.get<RivenditoreDto>('/rivenditore/getnuovo');
  }

  get(id: string): Observable<RivenditoreDto> {
    return this.http.get<RivenditoreDto>(`/rivenditore/get/${id}`);
  }

  post(document: RivenditoreDto): Observable<OperationResult<RivenditoreDto>> {
    return this.http
      .post<OperationResult<RivenditoreDto>>('/rivenditore/post', document);
  }

  put(document: RivenditoreDto): Observable<OperationResult<RivenditoreDto>> {
    return this.http
      .put<OperationResult<RivenditoreDto>>('/rivenditore/put', document);
  }

  getAclEntries(id: string, accountName: string): Observable<RivenditoreDto> {
    return this.http.get<RivenditoreDto>(`/rivenditore/getaclentries/${id}?accountName=${accountName}`);
  }

  // grantAccess(id: string, aclEntryDto: AclEntryDto): Observable<OperationResult<RivenditoreDto>> {
  //   return this.http
  //     .post<OperationResult<RivenditoreDto>>(`/rivenditore/grantaccess/${id}?aclEntryDto=${aclEntryDto}`, document);
  // }

  // revokeAccess(id: string, aclEntryDto: AclEntryDto): Observable<OperationResult<RivenditoreDto>> {
  //   return this.http
  //     .post<OperationResult<RivenditoreDto>>(`/rivenditore/revokeaccess/${id}?aclEntryDto=${aclEntryDto}`, document);
  // }
}
