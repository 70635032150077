import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CreateCodiceLicenzaDto } from 'app/models/CreateCodiceLicenzaDto';
import { PagedList } from 'app/models/PagedList';
import { CodiceLicenzaDto } from 'app/models/CodiceLicenzaDto';
import { HttpParamsExtension } from '../extensions/httpParamsExtension';
import { TipoLicenzaDto } from 'app/models/TipoLicenzaDto';
import { OperationResult } from 'app/models/OperationResult';
import { ActivateLicenseStatus } from 'app/models/ActivateLicenseStatus';


@Injectable({
    providedIn: 'root'
})
export class LicenzaService {

    constructor(private http: HttpClient) { }

    getNuovo(): Observable<CreateCodiceLicenzaDto> {
        return this.http.get<CreateCodiceLicenzaDto>('/licenza/getnuovo');
    }

    getCodiciLicenze(page: number, pageSize: number = 10, filter: string = ''): Observable<PagedList<CodiceLicenzaDto>> {
        const httpParams = HttpParamsExtension.ToHttpParams({
            page: page,
            pageSize: pageSize,
            filter: filter
        });

        return this.http
            .get<PagedList<CodiceLicenzaDto>>('/licenza/GetCodiciLicenze', { params: httpParams });
    };

    getTipiLicenze(page: number, pageSize: number = 10, filter: string = ''): Observable<PagedList<TipoLicenzaDto>> {
        const httpParams = HttpParamsExtension.ToHttpParams({
            page: page,
            pageSize: pageSize,
            filter: filter
        });

        return this.http
            .get<PagedList<TipoLicenzaDto>>('/licenza/GetTipiLicenze', { params: httpParams });
    };

    postCodiceLicenza(dto: CreateCodiceLicenzaDto): Observable<OperationResult<CodiceLicenzaDto[]>> {
        return this.http.post<OperationResult<CodiceLicenzaDto[]>>('/licenza/postCodiceLicenza', dto);
    }

    attivaLicenza(idUtente: string, idCodiceLicenza: string): Observable<ActivateLicenseStatus> {
        const httpParams = HttpParamsExtension.ToHttpParams({
            idUtente: idUtente,
            idCodiceLicenza: idCodiceLicenza,
        });

        return this.http.post<ActivateLicenseStatus>('/licenza/attivaLicenza', {}, { params: httpParams });

    }
}
