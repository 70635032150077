<!--Footer Starts-->
<footer>
    <div class="container-fluid">
        <p class="copyright text-center">
            Copyright &copy; {{currentDate | date: 'yyyy'}} <a href="http://www.mgbsuite.com/">MGB suite</a> - All
            rights reserved.
        </p>

    </div>
</footer>
<!--Footer Ends-->