import { environment } from 'environments/environment';
import { HttpParamsExtension } from '../extensions/httpParamsExtension';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var RivenditoreService = /** @class */ (function () {
    function RivenditoreService(http) {
        this.http = http;
    }
    RivenditoreService.prototype.getLogo = function (id) {
        // const authToken = this.authService.getToken().access_token;
        return environment.baseApiUrl + "/rivenditore/getlogo/" + id;
    };
    RivenditoreService.prototype.list = function (page, pageSize, filter) {
        if (pageSize === void 0) { pageSize = 10; }
        if (filter === void 0) { filter = ''; }
        var httpParams = HttpParamsExtension.ToHttpParams({
            page: page,
            pageSize: pageSize,
            filter: filter
        });
        return this.http
            .get('/rivenditore/get', { params: httpParams });
    };
    ;
    RivenditoreService.prototype.getNuovo = function () {
        return this.http.get('/rivenditore/getnuovo');
    };
    RivenditoreService.prototype.get = function (id) {
        return this.http.get("/rivenditore/get/" + id);
    };
    RivenditoreService.prototype.post = function (document) {
        return this.http
            .post('/rivenditore/post', document);
    };
    RivenditoreService.prototype.put = function (document) {
        return this.http
            .put('/rivenditore/put', document);
    };
    RivenditoreService.prototype.getAclEntries = function (id, accountName) {
        return this.http.get("/rivenditore/getaclentries/" + id + "?accountName=" + accountName);
    };
    RivenditoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RivenditoreService_Factory() { return new RivenditoreService(i0.ɵɵinject(i1.HttpClient)); }, token: RivenditoreService, providedIn: "root" });
    return RivenditoreService;
}());
export { RivenditoreService };
