import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { RivenditoreService } from '../services/rivenditore.service';

@Component({
  selector: 'app-distributed-by',
  templateUrl: './distributed-by.component.html',
  styleUrls: ['./distributed-by.component.scss']
})
export class DistributedByComponent implements OnInit {
  logoRivenditore = '';
  idRivenditore = '';

  constructor(private authService: AuthService, private rivenditoreService: RivenditoreService) { }

  ngOnInit() {
    this.idRivenditore = this.authService.getIdRivenditore();
    if (this.idRivenditore) {
      this.logoRivenditore = this.rivenditoreService.getLogo(this.idRivenditore);
    }
  }

}
