import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpParamsExtension } from '../extensions/httpParamsExtension';
import { PagedList } from 'app/models/PagedList';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { UtenteDto } from 'app/models/UtenteDto';
import { OperationResultBase } from 'app/models/OperationResultBase';
import { AuthToken } from 'app/models/AuthToken';
import { ResetPasswordDto } from 'app/models/ResetPasswordDto';
import { RegistraUtenteDto } from 'app/models/RegistraUtenteDto';
import { ConfermaEmailDto } from 'app/models/ConfermaEmailDto';
import { RecuperaPasswordDto } from 'app/models/RecuperaPasswordDto';
import { CambiaPasswordDto } from 'app/models/CambiaPasswordDto';
import { UtenteInfoDto } from 'app/models/UtenteInfoDto';
import { UtenteStatsDto } from 'app/models/UtenteStatsDto';
import { AllegatoDto } from 'app/models/AllegatoDto';
import { OperationResult } from 'app/models/OperationResult';

@Injectable()
export class AuthService {
  userUpdated = new BehaviorSubject<UtenteDto>(null);

  constructor(private http: HttpClient) { }

  getAllegati(hasVersione: boolean, categoria: string): Observable<AllegatoDto[]> {
    return this.http.get<AllegatoDto[]>(`/account/getallegati?hasVersione=${hasVersione}&categoria=${categoria}`);
  }

  getAllegatoUrl(id: string, token: string): string {
    return `${environment.baseApiUrl}/account/getallegato/${id}?access_token=${token}`;
  }

  postUploadAttachmentUrl(versione: string, categoria: string, token: string): string {
    return `${environment.baseApiUrl}/account/uploadallegato/?versione=${versione}&categoria=${categoria}&access_token=${token}`;
  }

  deleteAllegato(id: string): Observable<any> {
    return this.http.delete(`/account/deleteallegato/${id}`);
  }

  addTagAllegato(idAllegato: string, tag: string) {
    const data = {
      id: idAllegato,
      tag: tag
    }
    return this.http.post(`/account/addtagallegato/${idAllegato}?tag=${tag}`, data);
  }

  deleteTagAllegato(idAllegato: string, tag: string) {
    return this.http.delete(`/account/deletetagallegato/${idAllegato}?&tag=${tag}`);
  }

  get(id: string): Observable<UtenteInfoDto> {
    return this.http.get<UtenteInfoDto>(`/account/get/${id}`);
  }

  put(document: UtenteInfoDto): Observable<OperationResult<UtenteInfoDto>> {
    return this.http
      .put<OperationResult<UtenteInfoDto>>('/account/put', document);
  }
  
  list(page: number,
    pageSize: number = 10,
    filter: string = '',
    isDemo: boolean = null,
    idRivenditore: string = null, expired: boolean = null,
    showNfr: boolean = false, month: string = null, year: string = null): Observable<PagedList<UtenteInfoDto>> {
    const httpParams = HttpParamsExtension.ToHttpParams({
      page: page,
      pageSize: pageSize,
      filter: filter,
      isDemo: isDemo,
      idRivenditore: idRivenditore,
      expired: expired,
      showNfr: showNfr,
      month: month,
      year: year
    });

    return this.http
      .get<PagedList<UtenteInfoDto>>('/account/getAll', { params: httpParams });
  };

  getLogoUrl(id: string): string {
    const v = new Date().getTime().toString();
    return `${environment.baseApiUrl}/account/getLogo/${id}?v=${v}`;
  }

  postUploadLogoUrl(token: string): string {
    return `${environment.baseApiUrl}/account/uploadLogo?access_token=${token}`;
  }

  eliminaLogo(): Observable<OperationResultBase> {
    return this.http
      .delete<OperationResultBase>('/account/deleteLogo');
  }

  refreshUser(): Observable<UtenteDto> {
    return this.http
      .get<UtenteDto>('/account/get');
  }

  getStats(): Observable<UtenteStatsDto> {
    return this.http
      .get<UtenteStatsDto>('/account/GetStats');
  }

  refreshToken(refreshToken: string): Observable<AuthToken> {
    const params = new HttpParams()
      // .set('client_id', this.clientId)
      // .set('client_secret', this.clientSecret)
      .set('grant_type', 'refresh_token')
      .set('refresh_token', refreshToken);

    return this.http
      .post<AuthToken>('/token', params.toString(), {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      });
  };

  login(email: string, password: string): Observable<AuthToken> {
    const params = new HttpParams()
      .set('grant_type', 'password')
      .set('username', email)
      .set('password', password);

    return this.http
      .post<AuthToken>('/token', params.toString(), {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      });
  }

  logout() {
    this.setToken(null);
    this.setUser(null);
  }
  aggiornaProfilo(data: UtenteDto): Observable<OperationResultBase> {
    return this.http.post<OperationResultBase>('/account/aggiornaprofilo', data);
  }
  registra(data: RegistraUtenteDto): Observable<OperationResultBase> {
    return this.http.post<OperationResultBase>('/account/registra', data);
  }
  resetPassword(data: ResetPasswordDto): Observable<OperationResultBase> {
    return this.http.post<OperationResultBase>('/account/resetpassword', data);
  }
  passwordimenticata(data: RecuperaPasswordDto): Observable<OperationResultBase> {
    return this.http.post<OperationResultBase>('/account/passworddimenticata', data);
  }
  confermaEmail(data: ConfermaEmailDto) {
    return this.http.post('/Account/confermaemail', data);
  }
  cambiaPassword(data: CambiaPasswordDto): Observable<OperationResultBase> {
    return this.http.post<OperationResultBase>('/account/cambiapassword ', data);
  }
  attivaAutenticazioneDueFattori() {
    return this.http.post('/account/attivaautenticazioneduefattori ', {});
  }
  disattivaAutenticazioneDueFattori() {
    return this.http.post('/account/disattivaautenticazioneduefattori ', {});
  }

  setUser(user: UtenteDto) {
    this.setToSessionStorage<UtenteDto>('user', user);
    if (user) {
      this.setToLocalStorage<string>('idrivenditore', user.Licenza.IdRivenditore);
    }
    this.userUpdated.next(user);
  }
  getUser(): UtenteDto {
    return this.getFromSessionStorage<UtenteDto>('user');
  }
  getIdRivenditore(): string {
    return this.getFromLocalStorage<string>('idrivenditore');
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    if (token) {
      return true;
    }
    return false;
  }

  isInRole(roles: Array<string>): boolean {
    if (roles && this.isAuthenticated()) {
      const userRoles = this.getUser().Roles;
      const found = roles.some(v => userRoles.indexOf(v) !== -1);
      return found;
    }
    return false;
  }

  setToken(token: AuthToken) {
    this.setToSessionStorage<AuthToken>('token', token);
  }
  getToken(): AuthToken {
    return this.getFromSessionStorage<AuthToken>('token');
  }

  private getFromSessionStorage<T>(key: string): T {
    const settingString = sessionStorage.getItem(key);
    const setting = <T>JSON.parse(settingString, this.dateParser);
    return setting;
  }
  private setToSessionStorage<T>(key: string, value: T) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  private getFromLocalStorage<T>(key: string): T {
    const settingString = localStorage.getItem(key);
    const setting = <T>JSON.parse(settingString, this.dateParser);
    return setting;
  }
  private setToLocalStorage<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  dateParser(key, value) {
    const iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;
    if (value === null || value === undefined || Array.isArray(value)) {
      return value;
    }

    if (iso8601.test(value)) {
      return new Date(value);
    }

    return value;
  }
}
