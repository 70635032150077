export class EnumExtension {
    static getName(e: any, value: number, splitCamelCase: boolean = true): string {
        const values = this.getNamesAndValues(e, splitCamelCase);
        const result = values.find(x => x.value === value);
        if (result) {
            return result.name;
        } else {
            return '';
        }
    }


    static getNamesAndValues(e: any, splitCamelCase: boolean = true): { name: string; value: number; }[] {
        const result = EnumExtension.getNames(e, true).map(n => ({ name: n, value: e[n] }));
        if (splitCamelCase) {
            return result.map(n => ({
                name: n.name.replace(/([a-z](?=[A-Z]))/g, '$1 ')
                , value: n.value
            }));
        }
        return result;
    }

    static getNames(e: any, splitCamelCase: boolean = true): string[] {
        const result = EnumExtension.getObjValues(e).filter(v => typeof v === 'string') as string[];
        return result;
    }

    private static getObjValues(e: any): (number | string)[] {
        return Object.keys(e).map(k => e[k]);
    }
}
