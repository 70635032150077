<div class="bs-callout bs-callout-{{type}}">
  <h4 class="{{type}} form-section" (click)="toggle()" [ngStyle]="{'cursor':canExpand ? 'pointer' : 'default' }">
    <button class="btn btn-link" *ngIf="canExpand">
      <i class="{{type}} fa"
        [ngClass]="{'fa-chevron-circle-down': isExpanded, 'fa-chevron-circle-right' : !isExpanded}"></i>
    </button>
    <ng-content select="[title]"></ng-content>
  </h4>
  <div *ngIf="isExpanded">
    <ng-content select="[body]"></ng-content>
  </div>
</div>