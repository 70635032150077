import { ActivatedRoute } from '@angular/router';
import { SessoEnum } from './../../models/SessoEnum';
import { Component, OnInit } from '@angular/core';
import { UtenteDto } from 'app/models/UtenteDto';
import { DtoValidationResult } from 'app/models/DtoValidationResult';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'app/shared/auth/auth.service';
import { NgForm } from '@angular/forms';
import { CambiaPasswordDto } from 'app/models/CambiaPasswordDto';
import { FileUploader } from 'ng2-file-upload';
import { EnumExtension } from 'app/shared/extensions/enumExtension';
import { SettingsPageViewModeEnum } from 'app/models/ViewModeEnum';

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent implements OnInit {
  user: UtenteDto;
  validationResult = new DtoValidationResult();
  validationResultPassword = new DtoValidationResult();

  vecchiaPassword = '';
  nuovaPassword = '';
  confermaNuovaPassword = '';

  isSubmitting = false;
  uploader: FileUploader;
  sesso: { name: string; value: number; }[];

  settingsPageViewMode = SettingsPageViewModeEnum;
  viewmode: SettingsPageViewModeEnum;

  logo: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private authService: AuthService) { }

  ngOnInit() {
    this.viewmode = <SettingsPageViewModeEnum>this.activatedRoute.snapshot.data.viewmode;
    this.initializeUploader();
    this.user = this.authService.getUser();
    this.logo = this.getLogoUrl();
    this.authService.userUpdated.subscribe(x => {
      this.logo = this.getLogoUrl();
    })
    this.sesso = EnumExtension.getNamesAndValues(SessoEnum);
  }

  initializeUploader() {
    const uploadUrl = this.authService.postUploadLogoUrl(this.authService.getToken().access_token);
    this.uploader = new FileUploader({
      url: uploadUrl,
      isHTML5: true,
      autoUpload: true,
      // maxFileSize: 10,
      removeAfterUpload: true
    });
    this.uploader.onCompleteAll = () => {
      this.uploader.clearQueue();
      this.user.HasLogo = true;
      this.authService.setUser(this.user)
    };
  }

  getLogoUrl() {
    return this.authService.getLogoUrl(this.user.Id);
  }

  saveProfile(f: NgForm) {
    this.validationResult = null;

    if (!f.valid) {
      this.toastr.warning('Correggere gli errori e riprovare');
      return;
    }

    this.isSubmitting = true;

    this.authService.aggiornaProfilo(this.user).subscribe(response => {
      this.isSubmitting = false;
      if (response.HasErrors) {
        this.validationResult = response.ValidationResult;
        this.toastr.warning('Salvataggio NON effettuato');
      } else {
        this.toastr.success('Salvataggio effettuato');
        f.form.markAsPristine();
        this.authService.setUser(this.user)
      }
    });
  }

  deleteLogo() {
    this.isSubmitting = true;

    this.authService.eliminaLogo().subscribe(response => {
      this.isSubmitting = false;
      if (response.HasErrors) {
        this.validationResult = response.ValidationResult;
        this.toastr.warning('Logo NON eliminato');
      } else {
        this.toastr.success('Logo eliminato');
        this.user.HasLogo = false;
        this.authService.setUser(this.user)
      }
    });
  }

  changePassword(f: NgForm) {
    const model = new CambiaPasswordDto();
    model.VecchiaPassword = this.vecchiaPassword;
    model.NuovaPassword = this.nuovaPassword;
    model.ConfermaPassword = this.confermaNuovaPassword;

    this.validationResultPassword = null;

    if (!f.valid) {
      this.toastr.warning('Correggere gli errori e riprovare');
      return;
    }

    this.isSubmitting = true;

    this.authService.cambiaPassword(model).subscribe(response => {
      this.isSubmitting = false;
      if (response.HasErrors) {
        this.validationResultPassword = response.ValidationResult;
        this.toastr.warning('Password NON modificata');
      } else {
        this.toastr.success('Password modificata');
      }
    });
  }
}
