import { DatiPersonaFisicaDto } from './../../models/DatiPersonaFisicaDto';
import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { EnumExtension } from '../extensions/enumExtension';
import { SessoEnum } from 'app/models/SessoEnum';
import { PPEOptions, RedditoOptions } from './dati-persona-fisica-options';

@Component({
  selector: 'app-dati-persona-fisica-editor',
  templateUrl: './dati-persona-fisica-editor.component.html',
  styleUrls: ['./dati-persona-fisica-editor.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatiPersonaFisicaEditorComponent implements OnInit {
  // pepHelpText = 'In presenza di persone politicamente esposte (PPE), barrare l’opzione appropriata e riportare tutte le informazioni del caso: carica pubblica  della PPE; nome e cognome, carica pubblica e legame del familiare PPE; nome e cognome, carica pubblica e gli stretti legami con altra PPE; ecc. (Vedi Nota 3 dell’Allegato alla Dichiarazione del Cliente).'

  @Input()
  legend: string;

  @Input()
  persona: DatiPersonaFisicaDto;

  @Input()
  source: any;

  @Input()
  rowNumer = 0;

  @Input()
  mostraPep = true;

  @Input()
  mostraDataInizioAttivita = true

  @Input()
  mostraAmbitoTerritorialeAttivita = true;

  @Input()
  mostraReddito = false;

  @Input()
  mostraRecapiti = true;

  RedditoOptions: string[];
  PPEOptions: string[];

  sesso: { name: string; value: number; }[];

  componentId = Guid.create();

  private _namePreFix = '';

  @Input()
  get namePreFix(): string {
    return this._namePreFix + this.componentId;
  }
  set namePreFix(namePreFix: string) {
    this._namePreFix = namePreFix;
  }

  constructor(private controlContainer: ControlContainer) { }

  ngOnInit() {
    this.RedditoOptions = RedditoOptions;
    this.PPEOptions = PPEOptions;
    this.sesso = EnumExtension.getNamesAndValues(SessoEnum);
  }

  onRedditoSelected(value: string) {
    (<NgForm>this.controlContainer).form.markAsDirty();
    this.persona.Reddito = value;
  }

  onPosizionePoliticaSelected(value: string) {
    (<NgForm>this.controlContainer).form.markAsDirty();
    this.persona.PosizionePolitica = value;
  }

  onFamigliarePosizionePoliticaSelected(value: string) {
    (<NgForm>this.controlContainer).form.markAsDirty();
    this.persona.FamigliarePosizionePolitica = value;
  }

  onContiguoPosizionePoliticaSelected(value: string) {
    (<NgForm>this.controlContainer).form.markAsDirty();
    this.persona.ContiguoPosizionePolitica = value;
  }

}
