import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/shared/auth/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  isSubmitting = false;
  email = '';
  password = '';
  recaptchaToken = '';
  recaptchaEnabled = environment.production;

  constructor(private authService: AuthService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit() { }

  onSubmit() {
    this.isSubmitting = true;
    this.authService.login(this.email, this.password).subscribe(result => {
      this.isSubmitting = false;
      // console.log('login', result);
      this.authService.setToken(result);
      this.authService.refreshUser().subscribe(u => {
        this.authService.setUser(u);
        this.router.navigateByUrl('/');
      })
    }, err => {
      this.isSubmitting = false;
      this.toastr.error('Utente o password non validi');
    });
  }

  onForgotPassword() {
    this.router.navigate(['/forgotpassword']);
  }

  onRegister() {
    this.router.navigate(['/register']);
  }
}
