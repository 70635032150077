//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { TipoCollaborazioneEnum } from './TipoCollaborazioneEnum';

export class RegistraUtenteDto
{
	public Email: string;
	public Password: string;
	public Nome: string;
	public Cognome: string;
	public IdRivenditore: string;
	public Studio: string;
	public TipoCollaborazione: TipoCollaborazioneEnum;
}
