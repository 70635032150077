<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title text-center">
          <i class="ft-lock"></i>
          Licenza</h4>
      </div>
      <div class="card-body">
        <div class="card-block">
          <div class="row">
            <div class="col-md-6" *ngIf="!user.Licenza.IsDemo">
              <h4>
                <i class="ft-user"></i>
                Informazioni licenza</h4>
              <div>
                <strong>{{user.Licenza.NomeLicenza}}</strong>
                in collaborazione con {{user.Licenza.NomeRivenditore}}
                <img [src]="logoRivenditore" height="48" [title]="user.Licenza.NomeRivenditore">
              </div>
              <div>
                <strong>Data attivazione</strong>
              </div>
              <div>
                {{user.Licenza.DataAttivazione | date}}
              </div>
              <div>
                <strong>Data scadenza</strong>
              </div>
              <div [ngClass]="{'text-danger': user.Licenza.IsLicenzaScaduta}">
                {{user.Licenza.DataScadenza | date}}
              </div>
              <div>
                <strong>Numero Clienti</strong>
              </div>
              <div>
                {{user.Licenza.NumeroClienti}}
              </div>



            </div>
            <div class="col-md-6"  *ngIf="user.Licenza.IsDemo">
              <h4 class="card-title">
                <i class="ft-shield"></i>
                Attiva la tua licenza utilizzando il codice licenza</h4>
              <form novalidate class="form" #fAttivazione="ngForm">
                <div class="form-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Codice licenza</label>
                        <input type="text" class="form-control" name="codiceLicenza" [(ngModel)]="codiceLicenza"
                          required #inputCodiceLicenza="ngModel">
                        <app-control-validation-result [control]="inputCodiceLicenza">
                        </app-control-validation-result>
                      </div>
                    </div>
                  </div>
                </div>
                <button (click)="attivaCodiceLicenza(fAttivazione)" [ladda]="isSubmitting" type="button"
                  class="btn btn-raised btn-success" [disabled]="!fAttivazione.valid"
                  title="{{ inputCodiceLicenza.valid ? '' : 'Correggere gli errori'}}">
                  <i class="fa fa-floppy-o"></i> Attiva
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>