import { DatiPersonaFisicaDto } from './../../models/DatiPersonaFisicaDto';
import { OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { EnumExtension } from '../extensions/enumExtension';
import { SessoEnum } from 'app/models/SessoEnum';
import { PPEOptions, RedditoOptions } from './dati-persona-fisica-options';
var DatiPersonaFisicaEditorComponent = /** @class */ (function () {
    function DatiPersonaFisicaEditorComponent(controlContainer) {
        this.controlContainer = controlContainer;
        this.rowNumer = 0;
        this.mostraPep = true;
        this.mostraDataInizioAttivita = true;
        this.mostraAmbitoTerritorialeAttivita = true;
        this.mostraReddito = false;
        this.mostraRecapiti = true;
        this.componentId = Guid.create();
        this._namePreFix = '';
    }
    Object.defineProperty(DatiPersonaFisicaEditorComponent.prototype, "namePreFix", {
        get: function () {
            return this._namePreFix + this.componentId;
        },
        set: function (namePreFix) {
            this._namePreFix = namePreFix;
        },
        enumerable: true,
        configurable: true
    });
    DatiPersonaFisicaEditorComponent.prototype.ngOnInit = function () {
        this.RedditoOptions = RedditoOptions;
        this.PPEOptions = PPEOptions;
        this.sesso = EnumExtension.getNamesAndValues(SessoEnum);
    };
    DatiPersonaFisicaEditorComponent.prototype.onRedditoSelected = function (value) {
        this.controlContainer.form.markAsDirty();
        this.persona.Reddito = value;
    };
    DatiPersonaFisicaEditorComponent.prototype.onPosizionePoliticaSelected = function (value) {
        this.controlContainer.form.markAsDirty();
        this.persona.PosizionePolitica = value;
    };
    DatiPersonaFisicaEditorComponent.prototype.onFamigliarePosizionePoliticaSelected = function (value) {
        this.controlContainer.form.markAsDirty();
        this.persona.FamigliarePosizionePolitica = value;
    };
    DatiPersonaFisicaEditorComponent.prototype.onContiguoPosizionePoliticaSelected = function (value) {
        this.controlContainer.form.markAsDirty();
        this.persona.ContiguoPosizionePolitica = value;
    };
    return DatiPersonaFisicaEditorComponent;
}());
export { DatiPersonaFisicaEditorComponent };
