export const RedditoOptions = [
    'Compreso tra 0 e 50.000 euro',
    'Compreso tra 50.001 e 100.000 euro',
    'Compreso tra 100.001 e 250.000 euro ',
    'Compreso tra 250.001 e 500.000 euro',
    'Oltre 500.000 euro'
];

export const PPEOptions = [
    'Presidente della Repubblica Italiana o di stato estero',
    'Presidente del Consiglio italiano o carica analoga in stato estero',
    'Ministro italiano o carica analoga in stato estero',
    'Vice-Ministro italiano o carica analoga in stato estero',
    'Sottosegretario italiano o carica analoga in stato estero',
    'Deputato italiano o carica analoga in stato estero',
    'Senatore italiano o carica analoga in stato estero',
    'Parlamentare europeo o cariche analoghe in stato estero',
    'Presidente di Regione o carica analoga in stato estero',
    'Assessore regionale o carica analoga in stato estero',
    'Consigliere regionale italiano o carica analoga in stato estero',
    'Sindaco di capoluogo di provincia o città metropolitana italiana o carica analoga in stato estero',
    'Sindaco di comune con popolazione non inferiore a 15.000 abitanti o cariche analoghe in stato estero',
    'Direttore generale di ASL e di azienda ospedaliera, di azienda ospedaliera universitaria e degli altri enti del servizio sanitario nazionale',
    'Membro degli organi direttivi centrali di partiti politici italiani o esteri',
    'Giudice della Corte Costituzionale o carica analoga in stato estero',
    'Magistrato della Corte di Cassazione o carica analoga in stato estero',
    'Magistrato della Corte di Conti o carica analoga in stato estero',
    'Consigliere di Stato o carica analoga in stato estero',
    'Componente del Consiglio di Giustizia Amministrativa per la Regione siciliana',
    'Membro degli organi direttivi delle banche centrali e delle autorità indipendenti',
    'Ambasciatore italiano o carica equivalente in stato estero',
    'Incaricato d\'affari italiano o carica equivalente in stato estero',
    'Ufficiale di grado apicale delle forze armate italiane o carica equivalente in stato estero',
    'Componente degli organi di amministrazione, direzione o controllo di impresa controllata, anche indirettamente, dallo Stato italiano o estero',
    'Componente degli organi di amministrazione, direzione o controllo di impresa partecipata, in misura prevalente o totalitaria, dalle Regioni, da comuni capoluoghi di provincia e città metropolitane o da comuni con popolazione complessivamente non inferiore a 15.000 abitanti',
    'Direttore, Vicedirettore e membro dell\'organo di gestione o soggetto svolgente funzioni equivalenti in organizzazioni internazionali'
];
