<fieldset [ngModelGroup]="source">
  <h4 class="form-section" *ngIf="legend">
    {{legend}}
  </h4>
  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <label>Email</label>
        <input type="email" class="form-control" [(ngModel)]="recapiti.Email" name="{{namePreFix}}_{{rowNumer}}_Email">
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>Pec</label>
        <input type="email" class="form-control" [(ngModel)]="recapiti.Pec" name="{{namePreFix}}_{{rowNumer}}_Pec">
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label>Telefono</label>
        <input type="text" class="form-control" [(ngModel)]="recapiti.Telefono"
          name="{{namePreFix}}_{{rowNumer}}_Telefono">
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label>Cellulare</label>
        <input type="text" class="form-control" [(ngModel)]="recapiti.Cellulare"
          name="{{namePreFix}}_{{rowNumer}}_Cellulare">
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label>Fax</label>
        <input type="text" class="form-control" [(ngModel)]="recapiti.Fax" name="{{namePreFix}}_{{rowNumer}}_Fax">
      </div>
    </div>
  </div>
</fieldset>