import { OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
var ControlValidationResultComponent = /** @class */ (function () {
    function ControlValidationResultComponent() {
    }
    ControlValidationResultComponent.prototype.ngOnInit = function () { };
    Object.defineProperty(ControlValidationResultComponent.prototype, "errorMessage", {
        get: function () {
            if (this.control === undefined || this.control.errors === undefined) {
                return null;
            }
            for (var propertyName in this.control.errors) {
                if (this.control.errors.hasOwnProperty(propertyName) && this.isErrorProperty(propertyName)) {
                    var interpolateParams = this.control.errors;
                    var errMsg = this.isErrorProperty(propertyName, interpolateParams);
                    return errMsg;
                }
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    ControlValidationResultComponent.prototype.isErrorProperty = function (validatorName, validatorValue) {
        if (!validatorValue) {
            validatorValue = {};
        }
        var config = {
            min: "Il numero deve essere maggiore o uguale a " + validatorValue.requiredValue,
            max: "Il numero deve essere minore o uguale a " + validatorValue.requiredValue,
            required: 'Campo obbligatorio',
            minLengthArray: 'Campo obbligatorio',
            pattern: 'Campo non valido',
            invalidCreditCard: 'Campo non valido',
            invalidEmailAddress: 'Campo non valido',
            invalidPassword: 'Campo non valido',
            minlength: "Minimo " + validatorValue.requiredLength + " caratteri",
            maxlength: "Massimo " + validatorValue.requiredLength + " caratteri",
            email: 'Email non valida'
        };
        return config[validatorName];
    };
    ControlValidationResultComponent.prototype.isFormSubmitted = function () {
        if (this.control && this.control.formDirective) {
            return this.control.formDirective.submitted;
        }
        // No form
        return false;
    };
    return ControlValidationResultComponent;
}());
export { ControlValidationResultComponent };
