//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.
import * as tslib_1 from "tslib";
import { Dto } from './Dto';
import { DatiPersonaFisicaDto } from './DatiPersonaFisicaDto';
var CollaboratoreDto = /** @class */ (function (_super) {
    tslib_1.__extends(CollaboratoreDto, _super);
    function CollaboratoreDto() {
        var _this = _super.call(this) || this;
        _this.DatiPersonaFisica = new DatiPersonaFisicaDto();
        return _this;
    }
    return CollaboratoreDto;
}(Dto));
export { CollaboratoreDto };
