import { Component, OnInit, Input } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';

@Component({
  selector: 'app-control-validation-result',
  template: '<div *ngIf="errorMessage !== null"><div class="text-danger">{{errorMessage}}</div></div>',
  styleUrls: ['./control-validation-result.component.scss']
})
export class ControlValidationResultComponent implements OnInit {
  @Input() control: FormControl;

  constructor() { }

  ngOnInit() {  }

  get errorMessage() {
    if (this.control === undefined || this.control.errors === undefined) {
      return null;
    }

    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && this.isErrorProperty(propertyName)) {
        const interpolateParams = this.control.errors;
        const errMsg = this.isErrorProperty(propertyName, interpolateParams);
        return errMsg;
      }
    }

    return null;
  }


  isErrorProperty(validatorName: string, validatorValue?: any) {
    if (!validatorValue) {
      validatorValue = {};
    }

    const config = {
      min: `Il numero deve essere maggiore o uguale a ${validatorValue.requiredValue}`,
      max: `Il numero deve essere minore o uguale a ${validatorValue.requiredValue}`,
      required: 'Campo obbligatorio',
      minLengthArray: 'Campo obbligatorio',
      pattern: 'Campo non valido',
      invalidCreditCard: 'Campo non valido',
      invalidEmailAddress: 'Campo non valido',
      invalidPassword: 'Campo non valido',
      minlength: `Minimo ${validatorValue.requiredLength} caratteri`,
      maxlength: `Massimo ${validatorValue.requiredLength} caratteri`,
      email: 'Email non valida'
    };
    return config[validatorName];
  }

  private isFormSubmitted() {
    if (this.control && (<any>this.control).formDirective) {
      return (<NgForm>(<any>this.control).formDirective).submitted;
    }
    // No form
    return false;
  }
}

