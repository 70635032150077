//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.
import * as tslib_1 from "tslib";
import { IndirizzoBaseDto } from './IndirizzoBaseDto';
var IndirizzoDto = /** @class */ (function (_super) {
    tslib_1.__extends(IndirizzoDto, _super);
    function IndirizzoDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return IndirizzoDto;
}(IndirizzoBaseDto));
export { IndirizzoDto };
