import { CategoriaLicenzaEditorComponent } from './categoria-licenza-editor/categoria-licenza-editor.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { LaddaModule } from 'angular2-ladda';
import { ChartsModule } from 'ng2-charts';
import { FileUploadModule } from 'ng2-file-upload';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';
import { DistributedByComponent } from './distributed-by/distributed-by.component';
import { ControlValidationResultComponent } from './control-validation-result/control-validation-result.component';
import { ValidationResultComponent } from './validation-result/validation-result.component';
import { FormValidationResultComponent } from './form-validation-result/form-validation-result.component';

import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { SidebarDirective } from './directives/sidebar.directive';
import { SidebarLinkDirective } from './directives/sidebarlink.directive';
import { SidebarListDirective } from './directives/sidebarlist.directive';
import { SidebarAnchorToggleDirective } from './directives/sidebaranchortoggle.directive';
import { SidebarToggleDirective } from './directives/sidebartoggle.directive';
import { CheckRoleDirective } from './directives/check-role.directive';
import { StatoDocumentoEditorComponent } from './stato-documento-editor/stato-documento-editor.component';
import { IndirizzoBaseEditorComponent } from './indirizzo-base-editor/indirizzo-base-editor.component';
import { IndirizzoEditorComponent } from './indirizzo-editor/indirizzo-editor.component';
import { RecapitiEditorComponent } from './recapiti-editor/recapiti-editor.component';
import { StudioEditorComponent } from './studio-editor/studio-editor.component';
import { DatiPersonaFisicaEditorComponent } from './dati-persona-fisica-editor/dati-persona-fisica-editor.component';
import { DatiPersonaGiuridicaEditorComponent } from './dati-persona-giuridica-editor/dati-persona-giuridica-editor.component';
import { DatiDocumentoEditorComponent } from './dati-documento-editor/dati-documento-editor.component';
import { ExpanderComponent } from './expander/expander.component';
import { StrutturaSocialeEditorComponent } from './struttura-sociale-editor/struttura-sociale-editor.component';
import { StringArrayEditorComponent } from './string-array-editor/string-array-editor.component';
import { TitolareEffettivoEditorComponent } from './titolare-effettivo-editor/titolare-effettivo-editor.component';
import { DatiPrestazioneEditorComponent } from './dati-prestazione-editor/dati-prestazione-editor.component';
import { TipoPersonaViewerComponent } from './tipo-persona-viewer/tipo-persona-viewer.component';
import { AuditTimelineEditorComponent } from './audit-timeline-editor/audit-timeline-editor.component';
import { RevisionTimelineEditorComponent } from './revision-timeline-editor/revision-timeline-editor.component';
import { GroupByPipe } from './pipes/group-by.pipe';
import { Av1TabellaAEditorComponent } from './av1-tabella-aeditor/av1-tabella-aeditor.component';
import { Av1TabellaBEditorComponent } from './av1-tabella-beditor/av1-tabella-beditor.component';
import { ValoreNoteEditorComponent } from './valore-note-editor/valore-note-editor.component';
import { DescrizioneValoreArrayEditorComponent } from './descrizione-valore-array-editor/descrizione-valore-array-editor.component';
import { ValoreRischioPipe } from './pipes/valore-rischio.pipe';
import { RischioViewerComponent } from './rischio-viewer/rischio-viewer.component';
import { TipoVerificaViewerComponent } from './tipo-verifica-viewer/tipo-verifica-viewer.component';
import { PeriodicitaViewerComponent } from './periodicita-viewer/periodicita-viewer.component';
import { Av0TabellaAEditorComponent } from './av0-tabella-aeditor/av0-tabella-aeditor.component';
import { Av0TabellaBEditorComponent } from './av0-tabella-beditor/av0-tabella-beditor.component';
import { VulnerabilitaViewerComponent } from './vulnerabilita-viewer/vulnerabilita-viewer.component';
import { SoggettoPrestazioneEditorComponent } from './soggetto-prestazione-editor/soggetto-prestazione-editor.component';
import { AzioniPerMitigareRischioEditorComponent } from './azioni-per-mitigare-rischio-editor/azioni-per-mitigare-rischio-editor.component';
import { VulnerabilitaNoteAdempimentiEditorComponent } from './vulnerabilita-note-adempimenti-editor/vulnerabilita-note-adempimenti-editor.component';
import { DatiEsecutoreEditorComponent } from './dati-esecutore-editor/dati-esecutore-editor.component';
import { CollaboratoreEditorComponent } from './collaboratore-editor/collaboratore-editor.component';
import { ValutazioneVulnerabilitaEditorComponent } from './valutazione-vulnerabilita-editor/valutazione-vulnerabilita-editor.component';
import { SintesiRischioViewerComponent } from './sintesi-rischio-viewer/sintesi-rischio-viewer.component';
import { DatiPersonaFisicaViewerComponent } from './dati-persona-fisica-viewer/dati-persona-fisica-viewer.component';
import { DatiPersonaGiuridicaViewerComponent } from './dati-persona-giuridica-viewer/dati-persona-giuridica-viewer.component';
import { TitolareEffettivoViewerComponent } from './titolare-effettivo-viewer/titolare-effettivo-viewer.component';
import { PepViewerComponent } from './pep-viewer/pep-viewer.component';
import { OptionsModalComponent } from './options-modal/options-modal.component';
import { PfSocioTitolareEffettivoEditorComponent } from './pf-socio-titolare-effettivo-editor/pf-socio-titolare-effettivo-editor.component';
import { PgSocioTitolareEffettivoEditorComponent } from './pg-socio-titolare-effettivo-editor/pg-socio-titolare-effettivo-editor.component';
import { WarningMessageComponent } from './warning-message/warning-message.component';
import { StatoScadenzaViewerComponent } from './stato-scadenza-viewer/stato-scadenza-viewer.component';
import { CollaboratoreViewerComponent } from './collaboratore-viewer/collaboratore-viewer.component';
import { StudioViewerComponent } from './studio-viewer/studio-viewer.component';
import { RegolaTecnicaViewerComponent } from './regola-tecnica-viewer/regola-tecnica-viewer.component';
import { DocumentoFascicoloViewerComponent } from './documento-fascicolo-viewer/documento-fascicolo-viewer.component';
import { ScadenzarioStatsViewerComponent } from './scadenzario-stats-viewer/scadenzario-stats-viewer.component';
import { HoverClassDirective } from './directives/hoverclass.directive';
import { ColorPickerModule } from 'ngx-color-picker';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { BooleanViewerComponent } from './boolean-viewer/boolean-viewer.component';
import { HelpModalComponent } from './help-modal/help-modal.component';
import { DisableLicenseExpiredDirective } from './directives/disable-license-expired.directive';
import { ChecklistDirective } from './directives/checklist.directive';
import { CustomFormsModule } from 'ng2-validation';

@NgModule({
    exports: [
        CommonModule,
        FormsModule,
        CustomFormsModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        NgbModule,
        LaddaModule,
        // TranslateModule,
        DistributedByComponent,
        ControlValidationResultComponent,
        ValidationResultComponent,
        FormValidationResultComponent,
        FileUploadModule,
        CheckRoleDirective,
        ChecklistDirective,
        DisableLicenseExpiredDirective,
        CategoriaLicenzaEditorComponent,
        ChartsModule,
        StatoDocumentoEditorComponent,
        IndirizzoBaseEditorComponent,
        IndirizzoEditorComponent,
        RecapitiEditorComponent,
        StudioEditorComponent,
        DatiPersonaFisicaEditorComponent,
        DatiPersonaGiuridicaEditorComponent,
        DatiDocumentoEditorComponent,
        ExpanderComponent,
        StrutturaSocialeEditorComponent,
        StringArrayEditorComponent,
        TitolareEffettivoEditorComponent,
        DatiPrestazioneEditorComponent,
        TipoPersonaViewerComponent,
        AuditTimelineEditorComponent,
        RevisionTimelineEditorComponent,
        GroupByPipe,
        Av1TabellaAEditorComponent,
        Av1TabellaBEditorComponent,
        ValoreNoteEditorComponent,
        DescrizioneValoreArrayEditorComponent,
        ValoreRischioPipe,
        RischioViewerComponent,
        TipoVerificaViewerComponent,
        PeriodicitaViewerComponent,
        Av0TabellaAEditorComponent,
        Av0TabellaBEditorComponent,
        VulnerabilitaViewerComponent,
        SoggettoPrestazioneEditorComponent,
        AzioniPerMitigareRischioEditorComponent,
        VulnerabilitaNoteAdempimentiEditorComponent,
        DatiEsecutoreEditorComponent,
        CollaboratoreEditorComponent,
        ValutazioneVulnerabilitaEditorComponent,
        SintesiRischioViewerComponent,
        DatiPersonaFisicaViewerComponent,
        DatiPersonaGiuridicaViewerComponent,
        TitolareEffettivoViewerComponent,
        PepViewerComponent,
        OptionsModalComponent,
        PfSocioTitolareEffettivoEditorComponent,
        PgSocioTitolareEffettivoEditorComponent,
        WarningMessageComponent,
        StatoScadenzaViewerComponent,
        CollaboratoreViewerComponent,
        StudioViewerComponent,
        RegolaTecnicaViewerComponent,
        DocumentoFascicoloViewerComponent,
        ScadenzarioStatsViewerComponent,
        HoverClassDirective,
        AngularEditorModule,
        ColorPickerModule,
        BooleanViewerComponent,
        HelpModalComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        NgbModule,
        CustomFormsModule,
        // TranslateModule,
        PerfectScrollbarModule,
        LaddaModule,
        FileUploadModule,
        ChartsModule,
        ColorPickerModule,
        AngularEditorModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        SidebarLinkDirective,
        SidebarListDirective,
        SidebarAnchorToggleDirective,
        SidebarToggleDirective,
        DistributedByComponent,
        ControlValidationResultComponent,
        ValidationResultComponent,
        FormValidationResultComponent,
        CheckRoleDirective,
        ChecklistDirective,
        DisableLicenseExpiredDirective,
        CategoriaLicenzaEditorComponent,
        StatoDocumentoEditorComponent,
        IndirizzoBaseEditorComponent,
        IndirizzoEditorComponent,
        RecapitiEditorComponent,
        StudioEditorComponent,
        DatiPersonaFisicaEditorComponent,
        DatiPersonaGiuridicaEditorComponent,
        DatiDocumentoEditorComponent,
        ExpanderComponent,
        StrutturaSocialeEditorComponent,
        StringArrayEditorComponent,
        TitolareEffettivoEditorComponent,
        DatiPrestazioneEditorComponent,
        TipoPersonaViewerComponent,
        AuditTimelineEditorComponent,
        RevisionTimelineEditorComponent,
        GroupByPipe,
        Av1TabellaAEditorComponent,
        Av1TabellaBEditorComponent,
        ValoreNoteEditorComponent,
        DescrizioneValoreArrayEditorComponent,
        ValoreRischioPipe,
        RischioViewerComponent,
        TipoVerificaViewerComponent,
        PeriodicitaViewerComponent,
        Av0TabellaAEditorComponent,
        Av0TabellaBEditorComponent,
        VulnerabilitaViewerComponent,
        SoggettoPrestazioneEditorComponent,
        AzioniPerMitigareRischioEditorComponent,
        VulnerabilitaNoteAdempimentiEditorComponent,
        DatiEsecutoreEditorComponent,
        CollaboratoreEditorComponent,
        ValutazioneVulnerabilitaEditorComponent,
        SintesiRischioViewerComponent,
        DatiPersonaFisicaViewerComponent,
        DatiPersonaGiuridicaViewerComponent,
        TitolareEffettivoViewerComponent,
        PepViewerComponent,
        OptionsModalComponent,
        PfSocioTitolareEffettivoEditorComponent,
        PgSocioTitolareEffettivoEditorComponent,
        WarningMessageComponent,
        StatoScadenzaViewerComponent,
        CollaboratoreViewerComponent,
        StudioViewerComponent,
        RegolaTecnicaViewerComponent,
        DocumentoFascicoloViewerComponent,
        ScadenzarioStatsViewerComponent,
        HoverClassDirective,
        BooleanViewerComponent,
        HelpModalComponent
    ]
})
export class SharedModule { }
