import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupedOptionsDto } from 'app/models/GroupedOptionsDto';

@Component({
  selector: 'app-options-modal',
  templateUrl: './options-modal.component.html',
  styleUrls: ['./options-modal.component.scss']
})
export class OptionsModalComponent implements OnInit {
  @Input()
  modalTitle = ''

  @Input()
  multipleSelection = false;

  @Input()
  useGroupedOptions = false;

  @Input()
  options = new Array<string>();

  @Input()
  groupedOptions = new Array<GroupedOptionsDto>();

  @Input()
  showNoOption = false;

  @Output()
  itemSelected = new EventEmitter<string>();

  @Input()
  note = '';

  @Input()
  columnClass = 'text-center';

  selectedValues = new Array<string>();

  constructor(private modalService: NgbModal) { }

  ngOnInit() { }

  openModal(content) {
    this.selectedValues = new Array<string>();
    this.modalService.open(content, { size: 'lg' }).result.then((result) => {
      this.itemSelected.emit(result);
    }, (reason) => {
      if (reason === 'save' && this.multipleSelection) {
        const selectedValuesAsString = this.selectedValues
          .reduce((x, y) => `${x} ${y}.`, '');
        this.itemSelected.emit(selectedValuesAsString);
      }
    });
  }

}
