/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./validation-result.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./validation-result.component";
var styles_ValidationResultComponent = [i0.styles];
var RenderType_ValidationResultComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ValidationResultComponent, data: {} });
export { RenderType_ValidationResultComponent as RenderType_ValidationResultComponent };
function View_ValidationResultComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_ValidationResultComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ValidationResultComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.ErrorMessages; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ValidationResultComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "alert alert-danger m-t-sm"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ValidationResultComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.validationResult.ErrorResults; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ValidationResultComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ValidationResultComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.validationResult == null) ? null : ((_co.validationResult.ErrorResults == null) ? null : _co.validationResult.ErrorResults.length)) > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ValidationResultComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-validation-result", [], null, null, null, View_ValidationResultComponent_0, RenderType_ValidationResultComponent)), i1.ɵdid(1, 114688, null, 0, i3.ValidationResultComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ValidationResultComponentNgFactory = i1.ɵccf("app-validation-result", i3.ValidationResultComponent, View_ValidationResultComponent_Host_0, { validationResult: "validationResult" }, {}, []);
export { ValidationResultComponentNgFactory as ValidationResultComponentNgFactory };
