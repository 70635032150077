import { Component, OnInit, Input } from '@angular/core';
import { DtoValidationResult } from 'app/models/DtoValidationResult';

@Component({
  selector: 'app-validation-result',
  templateUrl: './validation-result.component.html',
  styleUrls: ['./validation-result.component.scss']
})
export class ValidationResultComponent implements OnInit {
  @Input()
  validationResult: DtoValidationResult;

  constructor() { }

  ngOnInit() {
  }

}
