import { IndirizzoBaseDto } from './../../models/IndirizzoBaseDto';
import { OnInit } from '@angular/core';
import { Guid } from 'guid-typescript';
var IndirizzoBaseEditorComponent = /** @class */ (function () {
    function IndirizzoBaseEditorComponent() {
        this.rowNumer = 0;
        this.componentId = Guid.create();
        this._namePreFix = '';
    }
    Object.defineProperty(IndirizzoBaseEditorComponent.prototype, "namePreFix", {
        get: function () {
            return this._namePreFix + this.componentId;
        },
        set: function (namePreFix) {
            this._namePreFix = namePreFix;
        },
        enumerable: true,
        configurable: true
    });
    IndirizzoBaseEditorComponent.prototype.ngOnInit = function () {
    };
    return IndirizzoBaseEditorComponent;
}());
export { IndirizzoBaseEditorComponent };
