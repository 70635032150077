<fieldset [ngModelGroup]="source">
  <h4 class="form-section" *ngIf="legend">
    {{legend}}
  </h4>
  <div class="row">
    <div class="col-md-2">
      <div class="form-group">
        <label>Tipo documento
          <app-options-modal [options]="TipoDocumentoOptions" [modalTitle]="'Tipo documento'"
            (itemSelected)="onItemSelected($event)">
          </app-options-modal>
        </label>
        <input type="text" class="form-control" [(ngModel)]="documento.TipoDocumento"
          name="{{namePreFix}}_{{rowNumer}}_TipoDocumento">
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label>Numero</label>
        <input type="text" class="form-control" [(ngModel)]="documento.Numero"
          name="{{namePreFix}}_{{rowNumer}}_Numero">
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label>Rilasciato da</label>
        <input type="text" class="form-control" [(ngModel)]="documento.RilasciatoDa"
          name="{{namePreFix}}_{{rowNumer}}_RilasciatoDa">
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label>Data rilascio</label>
        <div class="input-group">
          <input type="text" class="form-control" name="{{namePreFix}}_{{rowNumer}}_DataRilascio"
            [(ngModel)]="documento.DataRilascio" ngbDatepicker #d1="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="d1.toggle()">
              <i class="fa fa-calendar"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label>Data scadenza</label>
        <div class="input-group">
          <input type="text" class="form-control" name="{{namePreFix}}_{{rowNumer}}_DataScadenza"
            [(ngModel)]="documento.DataScadenza" ngbDatepicker #d2="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="d2.toggle()">
              <i class="fa fa-calendar"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <input type="checkbox" [(ngModel)]="documento.DatiNascitaResidenzaComeDocumento"
          name="DatiNascitaResidenzaComeDocumento">&nbsp; Dati di nascita e residenza come da
        documento di
        identificazione allegato
      </div>
    </div>
  </div>
</fieldset>