<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title text-center">
          <i class="fa fa-paint-brush"></i>
          Temi</h4>
      </div>
      <div class="card-body">
        <div class="card-block">
          <form>
            <div class="row" *ngIf="themes">
              <div class="col-md-12 mb-2">
                <div class="alert alert-success text-center">
                  Seleziona uno dei temi predefiniti o crea il tuo tema personalizzato, cliccando sui singoli pannelli
                  per scegliere il relativo colore e la tonalità del testo. Una volta completata la scelta, premere
                  “salva” per applicare la preferenza.
                </div>
              </div>
              <div class="col-md-12 text-center">
                <ng-container *ngFor="let t of themes">
                  <input type="radio" [(ngModel)]="themeId" name="Theme" [value]="t.id"
                    (ngModelChange)="onThemeChanged()"> {{t.name}} &nbsp;&nbsp;&nbsp;&nbsp;
                </ng-container>
              </div>

            </div>

            <ng-container *ngIf="theme">
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6 text-center mt-2">
                  <label>Sfondo generale</label>
                  <div style="cursor: pointer;" class="previewColor" [style.background]="theme.bodyColor"
                    *ngIf="!theme.isSystem" [cpPosition]="'bottom'" [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true" [(colorPicker)]="theme.bodyColor">
                    <span>{{theme.bodyColor}}</span>
                  </div>

                  <div class="previewColor" [style.background]="theme.bodyColor" *ngIf="theme.isSystem">
                    <span>{{theme.bodyColor}}</span>
                  </div>
                </div>
                <div class="col-md-3"></div>
                <div class="col-md-6 text-center mt-2">
                  <label>Dati studio - Sfondo</label>
                  <div style="cursor: pointer;" class="previewColor" [style.background]="theme.datiStudioColorPanel"
                    *ngIf="!theme.isSystem" [cpPosition]="'bottom'" [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true" [(colorPicker)]="theme.datiStudioColorPanel">
                    <span>{{theme.datiStudioColorPanel}}</span>
                  </div>

                  <div class="previewColor" [style.background]="theme.datiStudioColorPanel" *ngIf="theme.isSystem">
                    <span>{{theme.datiStudioColorPanel}}</span>
                  </div>
                </div>
                <div class="col-md-6 text-center mt-2">
                  <label>Dati studio - Testo</label>
                  <div style="cursor: pointer;" class="previewColor" [style.background]="theme.datiStudioColorText"
                    *ngIf="!theme.isSystem" [cpPosition]="'bottom'" [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true" [(colorPicker)]="theme.datiStudioColorText">
                    <span>{{theme.datiStudioColorText}}</span>
                  </div>
                  <div class="previewColor" [style.background]="theme.datiStudioColorText" *ngIf="theme.isSystem">
                    <span>{{theme.datiStudioColorText}}</span>
                  </div>
                </div>
                <div class="col-md-6 text-center mt-2">
                  <label>Strumenti e utilità - Sfondo</label>
                  <div style="cursor: pointer;" class="previewColor" [style.background]="theme.strumentiColorPanel"
                    *ngIf="!theme.isSystem" [cpPosition]="'bottom'" [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true" [(colorPicker)]="theme.strumentiColorPanel">
                    <span>{{theme.strumentiColorPanel}}</span>
                  </div>

                  <div class="previewColor" [style.background]="theme.strumentiColorPanel" *ngIf="theme.isSystem">
                    <span>{{theme.strumentiColorPanel}}</span>
                  </div>
                </div>
                <div class="col-md-6 text-center mt-2">
                  <label>Strumenti e utilità- Testo</label>
                  <div style="cursor: pointer;" class="previewColor" [style.background]="theme.strumentiColorText"
                    *ngIf="!theme.isSystem" [cpPosition]="'bottom'" [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true" [(colorPicker)]="theme.strumentiColorText">
                    <span>{{theme.strumentiColorText}}</span>
                  </div>
                  <div class="previewColor" [style.background]="theme.strumentiColorText" *ngIf="theme.isSystem">
                    <span>{{theme.strumentiColorText}}</span>
                  </div>
                </div>

                <div class="col-md-6 text-center mt-2">
                  <label>Novità - Sfondo</label>
                  <!-- <span [style.background]="theme.verificaColorPanel" [cpToggle]="true" [cpDialogDisplay]="'inline'"
                  [(colorPicker)]="theme.verificaColorPanel" [cpCancelButton]="true" [cpOKButton]="true"
                  [cpOKButtonClass]="'btn btn-success btn-raised'" [cpOKButtonText]="'OK'"
                  [cpCancelButtonClass]="'btn btn-danger btn-raised'" [cpCancelButtonText]="'Annulla'"
                  [cpSaveClickOutside]="false" *ngIf="!theme.isSystem"></span> -->
                  <div style="cursor: pointer;" class="previewColor" [style.background]="theme.novitaColorPanel"
                    *ngIf="!theme.isSystem" [cpPosition]="'bottom'" [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true" [(colorPicker)]="theme.novitaColorPanel">
                    <span>{{theme.novitaColorPanel}}</span>
                  </div>
                  <div class="previewColor" [style.background]="theme.novitaColorPanel" *ngIf="theme.isSystem">
                    <span>{{theme.novitaColorPanel}}</span>
                  </div>
                </div>
                <div class="col-md-6 text-center mt-2">
                  <label>Novità - Testo</label>
                  <div style="cursor: pointer;" class="previewColor" [style.background]="theme.novitaColorText"
                    *ngIf="!theme.isSystem" [cpPosition]="'bottom'" [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true" [(colorPicker)]="theme.novitaColorText">
                    <span>{{theme.novitaColorText}}</span>
                  </div>
                  <div class="previewColor" [style.background]="theme.novitaColorText" *ngIf="theme.isSystem">
                    <span>{{theme.novitaColorText}}</span>
                  </div>
                </div>


                <div class="col-md-6 text-center mt-2">
                  <label>Gestione studio - Sfondo</label>
                  <div style="cursor: pointer;" class="previewColor" [style.background]="theme.gestioneStudioColorPanel"
                    *ngIf="!theme.isSystem" [cpPosition]="'bottom'" [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true" [(colorPicker)]="theme.gestioneStudioColorPanel">
                    <span>{{theme.gestioneStudioColorPanel}}</span>
                  </div>
                  <div class="previewColor" [style.background]="theme.gestioneStudioColorPanel" *ngIf="theme.isSystem">
                    <span>{{theme.gestioneStudioColorPanel}}</span>
                  </div>
                </div>
                <div class="col-md-6 text-center mt-2">
                  <label>Gestione studio - Testo</label>
                  <div style="cursor: pointer;" class="previewColor" [style.background]="theme.gestioneStudioColorText"
                    *ngIf="!theme.isSystem" [cpPosition]="'bottom'" [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true" [(colorPicker)]="theme.gestioneStudioColorText">
                    <span>{{theme.gestioneStudioColorText}}</span>
                  </div>
                  <div class="previewColor" [style.background]="theme.gestioneStudioColorText" *ngIf="theme.isSystem">
                    <span>{{theme.gestioneStudioColorText}}</span>
                  </div>
                </div>
                <div class="col-md-6 text-center mt-2">
                  <label>Scadenzario - Sfondo</label>
                  <!-- <span [style.background]="theme.scadenziarioColorPanel" [cpToggle]="true" [cpDialogDisplay]="'inline'"
                  [(colorPicker)]="theme.scadenziarioColorPanel" [cpCancelButton]="true" [cpOKButton]="true"
                  [cpOKButtonClass]="'btn btn-success btn-raised'" [cpOKButtonText]="'OK'"
                  [cpCancelButtonClass]="'btn btn-danger btn-raised'" [cpCancelButtonText]="'Annulla'"
                  [cpSaveClickOutside]="false" *ngIf="!theme.isSystem"></span> -->

                  <div style="cursor: pointer;" class="previewColor" [style.background]="theme.scadenziarioColorPanel"
                    *ngIf="!theme.isSystem" [cpPosition]="'bottom'" [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true" [(colorPicker)]="theme.scadenziarioColorPanel">
                    <span>{{theme.scadenziarioColorPanel}}</span>
                  </div>
                  <div class="previewColor" [style.background]="theme.scadenziarioColorPanel" *ngIf="theme.isSystem">
                    <span>{{theme.scadenziarioColorPanel}}</span>
                  </div>
                </div>
                <div class="col-md-6 text-center mt-2">
                  <label>Scadenzario - Testo</label>
                  <!-- <span [style.background]="theme.scadenziarioColorText" [cpToggle]="true" [cpDialogDisplay]="'inline'"
                  [(colorPicker)]="theme.scadenziarioColorText" [cpCancelButton]="true" [cpOKButton]="true"
                  [cpOKButtonClass]="'btn btn-success btn-raised'" [cpOKButtonText]="'OK'"
                  [cpCancelButtonClass]="'btn btn-danger btn-raised'" [cpCancelButtonText]="'Annulla'"
                  [cpSaveClickOutside]="false" *ngIf="!theme.isSystem"></span> -->
                  <div style="cursor: pointer;" class="previewColor" [style.background]="theme.scadenziarioColorText"
                    *ngIf="!theme.isSystem" [cpPosition]="'bottom'" [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true" [(colorPicker)]="theme.scadenziarioColorText">
                    <span>{{theme.scadenziarioColorText}}</span>
                  </div>
                  <div class="previewColor" [style.background]="theme.scadenziarioColorText" *ngIf="theme.isSystem">
                    <span>{{theme.scadenziarioColorText}}</span>
                  </div>
                </div>
                <div class="col-md-6 text-center mt-2">
                  <label>Adeguata verifica della clientela - Sfondo</label>
                  <div style="cursor: pointer;" class="previewColor" [style.background]="theme.verificaColorPanel"
                    *ngIf="!theme.isSystem" [cpPosition]="'bottom'" [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true" [(colorPicker)]="theme.verificaColorPanel">
                    <span>{{theme.verificaColorPanel}}</span>
                  </div>
                  <div class="previewColor" [style.background]="theme.verificaColorPanel" *ngIf="theme.isSystem">
                    <span>{{theme.verificaColorPanel}}</span>
                  </div>
                </div>
                <div class="col-md-6 text-center mt-2">
                  <label>Adeguata verifica della clientela - Testo</label>
                  <div style="cursor: pointer;" class="previewColor" [style.background]="theme.verificaColorText"
                    *ngIf="!theme.isSystem" [cpPosition]="'bottom'" [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true" [(colorPicker)]="theme.verificaColorText">
                    <span>{{theme.verificaColorText}}</span>
                  </div>
                  <div class="previewColor" [style.background]="theme.verificaColorText" *ngIf="theme.isSystem">
                    <span>{{theme.verificaColorText}}</span>
                  </div>
                </div>

                <div class="col-md-6 text-center mt-2">
                  <label>Conservazione - Sfondo</label>
                  <div style="cursor: pointer;" class="previewColor" [style.background]="theme.conservazioneColorPanel"
                    *ngIf="!theme.isSystem" [cpPosition]="'bottom'" [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true" [(colorPicker)]="theme.conservazioneColorPanel">
                    <span>{{theme.conservazioneColorPanel}}</span>
                  </div>
                  <div class="previewColor" [style.background]="theme.conservazioneColorPanel" *ngIf="theme.isSystem">
                    <span>{{theme.conservazioneColorPanel}}</span>
                  </div>
                </div>
                <div class="col-md-6 text-center mt-2">
                  <label>Conservazione - Testo</label>
                  <div style="cursor: pointer;" class="previewColor" [style.background]="theme.conservazioneColorText"
                    *ngIf="!theme.isSystem" [cpPosition]="'bottom'" [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true" [(colorPicker)]="theme.conservazioneColorText">
                    <span>{{theme.conservazioneColorText}}</span>
                  </div>
                  <div class="previewColor" [style.background]="theme.conservazioneColorText" *ngIf="theme.isSystem">
                    <span>{{theme.conservazioneColorText}}</span>
                  </div>
                </div>

                <div class="col-md-6 text-center mt-2">
                  <label>SOS Utilizzo del contante - Sfondo</label>
                  <div style="cursor: pointer;" class="previewColor"
                    [style.background]="theme.utilizzoContanteColorPanel" *ngIf="!theme.isSystem"
                    [cpPosition]="'bottom'" [cpPositionOffset]="'50%'" [cpPositionRelativeToArrow]="true"
                    [(colorPicker)]="theme.utilizzoContanteColorPanel">
                    <span>{{theme.utilizzoContanteColorPanel}}</span>
                  </div>
                  <div class="previewColor" [style.background]="theme.utilizzoContanteColorPanel"
                    *ngIf="theme.isSystem">
                    <span>{{theme.utilizzoContanteColorPanel}}</span>
                  </div>
                </div>
                <div class="col-md-6 text-center mt-2">
                  <label>SOS Utilizzo del contante - Testo</label>
                  <div style="cursor: pointer;" class="previewColor"
                    [style.background]="theme.utilizzoContanteColorText" *ngIf="!theme.isSystem" [cpPosition]="'bottom'"
                    [cpPositionOffset]="'50%'" [cpPositionRelativeToArrow]="true"
                    [(colorPicker)]="theme.utilizzoContanteColorText">
                    <span>{{theme.utilizzoContanteColorText}}</span>
                  </div>
                  <div class="previewColor" [style.background]="theme.utilizzoContanteColorText" *ngIf="theme.isSystem">
                    <span>{{theme.utilizzoContanteColorText}}</span>
                  </div>
                </div>

                <div class="col-md-6 text-center mt-2">
                  <label>Altri strumenti - Sfondo</label>
                  <div style="cursor: pointer;" class="previewColor" [style.background]="theme.altriStrumentiColorPanel"
                    *ngIf="!theme.isSystem" [cpPosition]="'bottom'" [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true" [(colorPicker)]="theme.altriStrumentiColorPanel">
                    <span>{{theme.altriStrumentiColorPanel}}</span>
                  </div>
                  <div class="previewColor" [style.background]="theme.altriStrumentiColorPanel" *ngIf="theme.isSystem">
                    <span>{{theme.altriStrumentiColorPanel}}</span>
                  </div>
                </div>
                <div class="col-md-6 text-center mt-2">
                  <label>Altri strumenti - Testo</label>
                  <div style="cursor: pointer;" class="previewColor" [style.background]="theme.altriStrumentiColorText"
                    *ngIf="!theme.isSystem" [cpPosition]="'bottom'" [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true" [(colorPicker)]="theme.altriStrumentiColorText">
                    <span>{{theme.altriStrumentiColorText}}</span>
                  </div>
                  <div class="previewColor" [style.background]="theme.altriStrumentiColorText" *ngIf="theme.isSystem">
                    <span>{{theme.altriStrumentiColorText}}</span>
                  </div>
                </div>

              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <button class="btn btn-raised btn-success  pull-right" (click)="save()">Salva</button>
                </div>
              </div>
            </ng-container>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>