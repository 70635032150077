<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title text-center">
          <i class="fa fa-envelope-o"></i>
          Notifiche</h4>
      </div>
      <div class="card-body">
        <div class="card-block">
          <form novalidate class="form" #f="ngForm">
            <app-validation-result [validationResult]="validationResult"></app-validation-result>
            <app-form-validation-result [form]="f"></app-form-validation-result>
            <div class="form-body">
              <div class="col-md-12">
                <div class="form-group">
                  <input type="checkbox" [(ngModel)]="user.NotificheScadenzarioAbilitate"
                    name="NotificheScadenzarioAbilitate" [value]="true">&nbsp;&nbsp; Invia notifiche 'Scadenzario' via email
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input type="checkbox" [(ngModel)]="user.NotificheNewsAbilitate"
                    name="NotificheNewsAbilitate" [value]="true">&nbsp;&nbsp; Invia notifiche 'Novità' via email
                </div>
              </div>
           
            </div>
            <button (click)="save(f)" [ladda]="isSubmitting" type="button"
              class="btn btn-raised btn-success pull-right" [disabled]="!f.valid"
              title="{{ f.valid ? '' : 'Correggere gli errori'}}">
              <i class="fa fa-floppy-o"></i> Salva
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>