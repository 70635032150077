import { CollaboratoreDto } from './../../models/CollaboratoreDto';
import { StudioDto } from './../../models/StudioDto';
import { OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { TipoCollaborazioneEnum } from 'app/models/TipoCollaborazioneEnum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
var StudioEditorComponent = /** @class */ (function () {
    function StudioEditorComponent(controlContainer, modalService) {
        this.controlContainer = controlContainer;
        this.modalService = modalService;
        this.rowNumer = 0;
        this.componentId = Guid.create();
        this._namePreFix = '';
    }
    Object.defineProperty(StudioEditorComponent.prototype, "namePreFix", {
        get: function () {
            return this._namePreFix + this.componentId;
        },
        set: function (namePreFix) {
            this._namePreFix = namePreFix;
        },
        enumerable: true,
        configurable: true
    });
    StudioEditorComponent.prototype.ngOnInit = function () {
    };
    StudioEditorComponent.prototype.trackByFn = function (index, item) {
        return index; // or item.id
    };
    StudioEditorComponent.prototype.addCollaboratore = function () {
        this.controlContainer.form.markAsDirty();
        var soggetto = new CollaboratoreDto();
        soggetto.TipoCollaborazione = TipoCollaborazioneEnum.Associato;
        this.studio.Collaboratori.push(soggetto);
    };
    StudioEditorComponent.prototype.deleteCollaboratore = function (index) {
        this.controlContainer.form.markAsDirty();
        this.studio.Collaboratori.splice(index, 1);
    };
    StudioEditorComponent.prototype.openModal = function (content) {
        this.modalService.open(content, { size: 'lg' }).result.then(function () {
            // this.closeResult = `Closed with: ${result}`;
        }, function () {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    };
    return StudioEditorComponent;
}());
export { StudioEditorComponent };
