
import { NgModule, LOCALE_ID, ErrorHandler, Injectable, Injector, InjectionToken } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { NgbDateAdapter, NgbDateNativeUTCAdapter, NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxCaptchaModule } from 'ngx-captcha';
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { FullLayoutComponent } from './layouts/full/full-layout.component';

import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { PrivacyPolicyPageComponent } from './pages/privacy-policy-page/privacy-policy-page.component';
import { ToastrModule } from 'ngx-toastr';
import { AuthTokenInterceptor } from './shared/auth/auth-token-interceptor';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { SettingsPageComponent } from './pages/settings-page/settings-page.component';
import { LicensePageComponent } from './pages/license-page/license-page.component';
import { Angulartics2Module } from 'angulartics2';
import { TitolareEffettivoComponent } from './titolare-effettivo/titolare-effettivo.component';
import { NgbDateITParserFormatter } from './NgbDateITParserFormatter';
import { CustomizePageComponent } from './pages/customize-page/customize-page.component';

import * as Rollbar from 'rollbar';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Inject } from '@angular/core';
import { NotificationsPageComponent } from './pages/notifications-page/notifications-page.component';

registerLocaleData(localeIt, 'it-IT');

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};


export function rollbarFactory() {
  const rollbarConfig: Rollbar.Configuration = {
    accessToken: 'e77f87f8a6c04aeeaef565e17fc90649',
      captureUncaught: true,
      captureUnhandledRejections: true,
      environment: 'production',
      payload: {
        environment: 'production',
        client: {
          javascript: {
            source_map_enabled: true, // true by default
            
            // -- Add this into your configuration ---
            code_version: '1.0',
            // ---------------------------------------
            
            // Optionally have Rollbar guess which frames the error was 
            // thrown from when the browser does not provide line 
            // and column numbers.
            guess_uncaught_frames: true
          }
        }
      }
  };

    return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector, @Inject(RollbarService) private rollbar: Rollbar) { }

  handleError(error): void {
      console.error('Exception handled - ', error);

      const production = environment.production;
      if (production) {
          this.rollbar.error(error.originalError || error);
          const router = this.injector.get(Router);
          const authService = this.injector.get(AuthService);
          authService.logout();
          router.navigate(['/error']);
      } else {
          alert('Errore inaspettato');
      }
  }
}


@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    LoginPageComponent,
    RegisterPageComponent,
    ForgotPasswordPageComponent,
    ResetPasswordPageComponent,
    ErrorPageComponent,
    NotFoundPageComponent,
    PrivacyPolicyPageComponent,
    SettingsPageComponent,
    LicensePageComponent,
    TitolareEffettivoComponent,
    CustomizePageComponent,
    NotificationsPageComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    NgbModule.forRoot(),
    NgxCaptchaModule,
    ToastrModule.forRoot(),
    PerfectScrollbarModule,
    Angulartics2Module.forRoot(),
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: createTranslateLoader,
    //     deps: [HttpClient]
    //   }
    // }),
  ],
  providers: [
    AuthService,
    { provide: RollbarService, useFactory: rollbarFactory },
    AuthGuard,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'it-IT'
    },
    {
      provide: NgbDateAdapter,
      // useClass: NgbDateNativeAdapter
      useClass: NgbDateNativeUTCAdapter
    },
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateITParserFormatter
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
