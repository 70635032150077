import { Injectable } from '@angular/core';
import { ThemeDto } from 'app/models/ThemeDto';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private themes = new Array<ThemeDto>();

  private theme1: ThemeDto = {
    id: 'A7A8A4F5-CB94-4212-BDE0-63B1EF1C0DE3',
    isSystem: true,
    name: 'Tema 1',

    bodyColor: '#ECECEC',

    datiStudioColorPanel: '#FFFFFF',
    datiStudioColorText: '#428BCA',

    strumentiColorPanel: '#FFFFFF',
    strumentiColorText: '#1CBCD8',

    gestioneStudioColorPanel: '#FFFFFF',
    gestioneStudioColorText: '#FF586B',

    scadenziarioColorPanel: '#FFFFFF',
    scadenziarioColorText: '#FF8D60',

    verificaColorPanel: '#FFFFFF',
    verificaColorText: '#0CC27E',

    novitaColorPanel: '#FFFFFF',
    novitaColorText: '#A641A6',

    conservazioneColorPanel: '#FFFFFF',
    conservazioneColorText: '#2EC20C',

    utilizzoContanteColorPanel: '#FFFFFF',
    utilizzoContanteColorText: '#C2270C',

    altriStrumentiColorPanel: '#FFFFFF',
    altriStrumentiColorText: '#281CD8',
  };

  private theme2: ThemeDto = {
    id: '4751BC66-3476-4B38-923F-0E59240CF584',
    isSystem: true,
    name: 'Tema 2',

    bodyColor: '#E4CFE5',

    datiStudioColorPanel: '#FFD700',
    datiStudioColorText: '#FFFFFF',

    strumentiColorPanel: '#64E4EE',
    strumentiColorText: '#FFFFFF',

    gestioneStudioColorPanel: '#E383EE',
    gestioneStudioColorText: '#FFFFFF',

    scadenziarioColorPanel: '#FFA500',
    scadenziarioColorText: '#FFFFFF',

    verificaColorPanel: '#56EB56',
    verificaColorText: '#FFFFFF',

    novitaColorPanel: '#FF00FF',
    novitaColorText: '#FFFFFF',

    conservazioneColorPanel: '#A0EB56',
    conservazioneColorText: '#FFFFFF',

    utilizzoContanteColorPanel: '#EB56EB',
    utilizzoContanteColorText: '#FFFFFF',

    altriStrumentiColorPanel: '#A056EB',
    altriStrumentiColorText: '#FFFFFF'
  };

  private theme3: ThemeDto = {
    id: '51BA06D3-EA81-4BC0-B1D2-DC108A6C7DF2',
    isSystem: true,
    name: 'Tema 3',

    bodyColor: '#F7F3F7',

    datiStudioColorPanel: '#FCE970',
    datiStudioColorText: '#616161',

    strumentiColorPanel: '#68DFE8',
    strumentiColorText: '#616161',

    gestioneStudioColorPanel: '#FFDFDF',
    gestioneStudioColorText: '#616161',

    scadenziarioColorPanel: '#E5AD3E',
    scadenziarioColorText: '#616161',

    verificaColorPanel: '#7CFC7C',
    verificaColorText: '#616161',

    novitaColorPanel: '#FF00FF',
    novitaColorText: '#616161',

    conservazioneColorPanel: '#BCfC7C',
    conservazioneColorText: '#616161',

    utilizzoContanteColorPanel: '#FC7C7C',
    utilizzoContanteColorText: '#616161',

    altriStrumentiColorPanel: '#FC7CBC',
    altriStrumentiColorText: '#616161'
  };

  private themeCustom: ThemeDto = {
    id: '1D54B88D-C323-4978-80CD-A42339B6A374',
    isSystem: false,
    name: 'Personalizzato',

    bodyColor: '#ECECEC',

    datiStudioColorPanel: '#FFFFFF',
    datiStudioColorText: '#428BCA',

    strumentiColorPanel: '#FFFFFF',
    strumentiColorText: '#1CBCD8',

    gestioneStudioColorPanel: '#FFFFFF',
    gestioneStudioColorText: '#FF586B',

    scadenziarioColorPanel: '#FFFFFF',
    scadenziarioColorText: '#FF8D60',

    verificaColorPanel: '#FFFFFF',
    verificaColorText: '#0CC27E',

    novitaColorPanel: '#FFFFFF',
    novitaColorText: '#A641A6',

    conservazioneColorPanel: '#FFFFFF',
    conservazioneColorText: '#2EC20C',

    utilizzoContanteColorPanel: '#FFFFFF',
    utilizzoContanteColorText: '#C2270C',

    altriStrumentiColorPanel: '#FFFFFF',
    altriStrumentiColorText: '#281CD8',
  };


  constructor() {
    this.themes.push(this.theme1);
    this.themes.push(this.theme2);
    this.themes.push(this.theme3);
    this.themes.push(this.getCustomTheme());
  }

  getThemes() {
    return this.themes;
  }

  getCurrentTheme() {
    const savedThemeId = localStorage.getItem('MBGSelectedThemeId');
    if (savedThemeId) {
      return this.themes.find(x => x.id === savedThemeId);
    }

    return this.themes[0];
  }

  getCustomTheme() {
    const customThemeAsString = localStorage.getItem('MBGCustomTheme');
    const customTheme = customThemeAsString ? <ThemeDto>JSON.parse(customThemeAsString) : this.themeCustom;
    return Object.assign({}, this.themeCustom, customTheme);
  }

  save(theme: ThemeDto) {
    localStorage.setItem('MBGSelectedThemeId', theme.id);
    if (!theme.isSystem) {
      localStorage.setItem('MBGCustomTheme', JSON.stringify(theme));
    }
  }
}
