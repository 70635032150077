import { CollaboratoreDto } from './../../models/CollaboratoreDto';
import { StudioDto } from './../../models/StudioDto';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { TipoCollaborazioneEnum } from 'app/models/TipoCollaborazioneEnum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-studio-editor',
  templateUrl: './studio-editor.component.html',
  styleUrls: ['./studio-editor.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudioEditorComponent implements OnInit {
  @Input()
  legend: string;

  @Input()
  studio: StudioDto;

  @Input()
  source: any;

  @Input()
  rowNumer = 0;

  componentId = Guid.create();

  private _namePreFix = '';

  @Input()
  get namePreFix(): string {
    return this._namePreFix + this.componentId;
  }
  set namePreFix(namePreFix: string) {
    this._namePreFix = namePreFix;
  }

  constructor(private controlContainer: ControlContainer, private modalService: NgbModal) { }

  ngOnInit() {
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  addCollaboratore() {
    (<NgForm>this.controlContainer).form.markAsDirty();
    const soggetto = new CollaboratoreDto();
    soggetto.TipoCollaborazione = TipoCollaborazioneEnum.Associato;
    this.studio.Collaboratori.push(soggetto);
  }

  deleteCollaboratore(index: number) {
    (<NgForm>this.controlContainer).form.markAsDirty();
    this.studio.Collaboratori.splice(index, 1);
  }

  openModal(content) {
    this.modalService.open(content, { size: 'lg' }).result.then(() => {
      // this.closeResult = `Closed with: ${result}`;
    }, () => {

      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
