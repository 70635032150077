<!-- user: {{user | json}} -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title text-center">
          <i class="fa fa-briefcase"></i>
          Dati studio
        </h4>
        <hr>
      </div>
      <div class="card-body">
        <div class="card-block">
          <app-expander *ngIf="viewmode === settingsPageViewMode.DatiStudio">
            <ng-container title>
              Utente licenza: {{user?.Email}}
            </ng-container>
            <ng-container body>
              <form novalidate class="form" #f="ngForm">
                <app-validation-result [validationResult]="validationResult"></app-validation-result>
                <app-form-validation-result [form]="f"></app-form-validation-result>
                <div class="form-body">
                  <app-studio-editor [source]="user.Studio" [studio]="user.Studio" [namePreFix]="'Studio'">
                  </app-studio-editor>
                  <div class="row">
                    <div class="col-md-12">
                      <label>Logo</label>
                      <input type="file" ng2FileSelect [uploader]="uploader" *ngIf="!user.HasLogo" class="form-control">
                      <br>
                      <img class="img-responsive" [src]="logo" *ngIf="user.HasLogo" style="max-height: 150px;" /> <br>
                      <button *ngIf="user.HasLogo" (click)="deleteLogo()" class="btn btn-danger btn-raised">
                        <i class="fa fa-minus-circle"></i>Rimuovi logo</button>
                    </div>
                  </div>
                </div>
                <button (click)="saveProfile(f)" [ladda]="isSubmitting" type="button"
                  class="btn btn-raised btn-success pull-right" [disabled]="!f.valid"
                  title="{{ f.valid ? '' : 'Correggere gli errori'}}" *ngIf="f.dirty">
                  <i class="fa fa-floppy-o"></i> Salva
                </button>
              </form>
            </ng-container>
          </app-expander>
          <app-expander *ngIf="viewmode === settingsPageViewMode.Sicurezza">
            <ng-container title>
              Sicurezza
            </ng-container>
            <ng-container body>
              <form novalidate class="form" #fpassword="ngForm">
                <app-validation-result [validationResult]="validationResultPassword"></app-validation-result>
                <!-- <app-form-validation-result [form]="fpassword"></app-form-validation-result> -->
                <div class="form-body">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Vecchia password</label>
                        <input type="password" class="form-control" name="vecchiapassword" [(ngModel)]="vecchiaPassword"
                          required #inputVecchiaPassword="ngModel">
                        <app-control-validation-result [control]="inputVecchiaPassword">
                        </app-control-validation-result>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Nuova password</label>
                        <input type="password" class="form-control" name="nuovapassword" [(ngModel)]="nuovaPassword"
                          required #inputNuovaPassword="ngModel">
                        <app-control-validation-result [control]="inputNuovaPassword">
                        </app-control-validation-result>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Conferma nuova password</label>
                        <input type="password" class="form-control" name="confermaNuovaPassword"
                          [(ngModel)]="confermaNuovaPassword" required #inputConfermaNuovaPassword="ngModel">
                        <app-control-validation-result [control]="inputConfermaNuovaPassword">
                        </app-control-validation-result>
                      </div>
                    </div>
                  </div>
                </div>
                <button (click)="changePassword(fpassword)" [ladda]="isSubmitting" type="button"
                  class="btn btn-raised btn-success pull-right" [disabled]="!fpassword.valid"
                  title="{{ fpassword.valid ? '' : 'Correggere gli errori'}}">
                  <i class="fa fa-floppy-o"></i> Modifica password</button>
              </form>
            </ng-container>
          </app-expander>
        </div>
      </div>
    </div>
  </div>
</div>