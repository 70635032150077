import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[hover-class]'
})
export class HoverClassDirective {
    @Input('hover-class')
    hoverClass: string;

    constructor(public elementRef: ElementRef) { }

    @HostListener('mouseenter')
    onMouseEnter() {

        this.hoverClass.split(' ').forEach(x => {
            this.elementRef.nativeElement.classList.add(x);
        })

        // this.elementRef.nativeElement.classList.add(this.hoverClass);
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        //    this.elementRef.nativeElement.classList.remove(this.hoverClass);

        this.hoverClass.split(' ').forEach(x => {
            this.elementRef.nativeElement.classList.remove(x);
        })
    }
}
