import { Component, OnInit } from '@angular/core';
import { ThemeService } from 'app/shared/services/theme.service';
import { ThemeDto } from 'app/models/ThemeDto';
import { ObjectExtension } from 'app/shared/extensions/objectExtension';

@Component({
  selector: 'app-customize-page',
  templateUrl: './customize-page.component.html',
  styleUrls: ['./customize-page.component.scss']
})
export class CustomizePageComponent implements OnInit {
  themes: ThemeDto[];
  theme: ThemeDto;
  themeId: string;
  customTheme: ThemeDto;

  constructor(private themeService: ThemeService) { }

  ngOnInit() {
    this.themes = this.themeService.getThemes();
    this.theme = this.themeService.getCurrentTheme();
    this.themeId = this.theme.id;
    this.onThemeChanged();
  }

  onThemeChanged() {
    this.theme = ObjectExtension.clone(this.themes.find(x => x.id === this.themeId));
    if (!this.theme.isSystem) {
      // custom theme..restore last (inpage) modifications (if presents)
      if (this.customTheme) {
        this.theme = this.customTheme;
      } else {
        this.customTheme = this.theme;
      }
    }
  }

  save() {
    this.themeService.save(this.theme);
    window.location.reload();
  }
}
