//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class RecapitiDto
{
	public Email: string;
	public Pec: string;
	public Telefono: string;
	public Fax: string;
	public Cellulare: string;
}
