import { IndirizzoDto } from './../../models/IndirizzoDto';
import { OnInit } from '@angular/core';
import { Guid } from 'guid-typescript';
var IndirizzoEditorComponent = /** @class */ (function () {
    function IndirizzoEditorComponent() {
        this.rowNumer = 0;
        this.componentId = Guid.create();
        this._namePreFix = '';
    }
    Object.defineProperty(IndirizzoEditorComponent.prototype, "namePreFix", {
        get: function () {
            return this._namePreFix + this.componentId;
        },
        set: function (namePreFix) {
            this._namePreFix = namePreFix;
        },
        enumerable: true,
        configurable: true
    });
    IndirizzoEditorComponent.prototype.ngOnInit = function () {
    };
    return IndirizzoEditorComponent;
}());
export { IndirizzoEditorComponent };
