import { Routes } from '@angular/router';
import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { PrivacyPolicyPageComponent } from './pages/privacy-policy-page/privacy-policy-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { SettingsPageComponent } from './pages/settings-page/settings-page.component';
import { LicensePageComponent } from './pages/license-page/license-page.component';
import { SettingsPageViewModeEnum } from './models/ViewModeEnum';
import { CustomizePageComponent } from './pages/customize-page/customize-page.component';
import { NotificationsPageComponent } from './pages/notifications-page/notifications-page.component';
var ɵ0 = function () { return import("./pages/home/home.module.ngfactory").then(function (m) { return m.HomeModuleNgFactory; }); }, ɵ1 = function () { return import("./pages/clienti/clienti.module.ngfactory").then(function (m) { return m.ClientiModuleNgFactory; }); }, ɵ2 = function () { return import("./pages/av0/av0.module.ngfactory").then(function (m) { return m.Av0ModuleNgFactory; }); }, ɵ3 = function () { return import("./pages/av1/av1.module.ngfactory").then(function (m) { return m.Av1ModuleNgFactory; }); }, ɵ4 = function () { return import("./pages/av2/av2.module.ngfactory").then(function (m) { return m.Av2ModuleNgFactory; }); }, ɵ5 = function () { return import("./pages/av4/av4.module.ngfactory").then(function (m) { return m.Av4ModuleNgFactory; }); }, ɵ6 = function () { return import("./pages/av5/av5.module.ngfactory").then(function (m) { return m.Av5ModuleNgFactory; }); }, ɵ7 = function () { return import("./pages/av6/av6.module.ngfactory").then(function (m) { return m.Av6ModuleNgFactory; }); }, ɵ8 = function () { return import("./pages/av7/av7.module.ngfactory").then(function (m) { return m.Av7ModuleNgFactory; }); }, ɵ9 = function () { return import("./pages/scadenziario/scadenziario.module.ngfactory").then(function (m) { return m.ScadenziarioModuleNgFactory; }); }, ɵ10 = function () { return import("./pages/verifica-titolare-effettivo/verifica-titolare-effettivo.module.ngfactory").then(function (m) { return m.VerificaTitolareEffettivoModuleNgFactory; }); }, ɵ11 = function () { return import("./pages/rivenditori/rivenditori.module.ngfactory").then(function (m) { return m.RivenditoriModuleNgFactory; }); }, ɵ12 = function () { return import("./pages/utenti/utenti.module.ngfactory").then(function (m) { return m.UtentiModuleNgFactory; }); }, ɵ13 = function () { return import("./pages/news/news.module.ngfactory").then(function (m) { return m.NewsModuleNgFactory; }); }, ɵ14 = {
    viewmode: SettingsPageViewModeEnum.DatiStudio,
}, ɵ15 = {
    viewmode: SettingsPageViewModeEnum.Sicurezza,
};
var appRoutes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: '',
        component: FullLayoutComponent,
        children: [
            {
                path: 'dashboard',
                loadChildren: ɵ0
            },
            {
                path: 'clienti',
                loadChildren: ɵ1
            },
            {
                path: 'av0',
                loadChildren: ɵ2
            },
            {
                path: 'av1',
                loadChildren: ɵ3
            },
            {
                path: 'av2',
                loadChildren: ɵ4
            },
            {
                path: 'av4',
                loadChildren: ɵ5
            },
            {
                path: 'av5',
                loadChildren: ɵ6
            },
            {
                path: 'av6',
                loadChildren: ɵ7
            },
            {
                path: 'av7',
                loadChildren: ɵ8
            },
            {
                path: 'scadenziario',
                loadChildren: ɵ9
            },
            {
                path: 'verificatitolareeffettivo',
                loadChildren: ɵ10
            },
            {
                path: 'rivenditori',
                loadChildren: ɵ11
            },
            {
                path: 'utenti',
                loadChildren: ɵ12
            },
            {
                path: 'news',
                loadChildren: ɵ13
            },
            {
                path: 'licenze',
                loadChildren: './pages/licenze/licenze.module#LicenzeModule'
            },
            {
                path: 'updates',
                loadChildren: './pages/update/update.module#UpdateModule'
            },
            {
                path: 'tipilicenze',
                loadChildren: './pages/tipi-licenze/tipi-licenze.module#TipiLicenzeModule'
            },
            // {
            //   path: 'titolareeffettivo',
            //   component: TitolareEffettivoComponent
            // },
            {
                path: 'datistudio',
                component: SettingsPageComponent,
                data: ɵ14
            },
            {
                path: 'sicurezza',
                component: SettingsPageComponent,
                data: ɵ15
            },
            {
                path: 'license',
                component: LicensePageComponent
            },
            {
                path: 'customize',
                component: CustomizePageComponent
            },
            {
                path: 'notifications',
                component: NotificationsPageComponent
            }
        ], canActivate: [AuthGuard], canActivateChild: [AuthGuard]
    },
    // { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [AuthGuard] },
    // { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES, canActivate: [AuthGuard] },
    { path: 'login', component: LoginPageComponent },
    { path: 'privacypolicy', component: PrivacyPolicyPageComponent },
    { path: 'register', component: RegisterPageComponent },
    { path: 'forgotpassword', component: ForgotPasswordPageComponent },
    { path: 'resetpassword', component: ResetPasswordPageComponent },
    { path: 'error', component: ErrorPageComponent },
    { path: '**', component: NotFoundPageComponent },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15 };
