<!-- Navbar (Header) Starts -->
<nav class="header-navbar navbar navbar-expand-lg navbar-light bg-faded">
    <div class="container-fluid">
        <div class="navbar-header" style="  float: left;
        padding: 15px;
        text-align: center;
        width: 100%;">
            <a [hover-class]="'animate__animated animate__pulse'" class="btn btn-outline-success mr-2 pull-left"
                routerLink="" title="Home"><i class="fa fa-home font-large-2"></i></a>
            <a [hover-class]="'animate__animated animate__pulse'" class="btn btn-outline-primary mr-2 pull-left"
                routerLink="/customize" title="Temi"><img src="assets/img/dashboard/colorpalette.png" height="45"
                    width="45"></a>
            <a [hover-class]="'animate__animated animate__pulse'" class="btn btn-outline-secondary mr-2 pull-left"
                target="_new" href="https://www.mgbsuite.com" title="Guida"><img src="assets/img/dashboard/cap.png"
                    height="45" width="45"></a>
                    <a [hover-class]="'animate__animated animate__pulse'" class="btn btn-outline-warning mr-2 pull-left"
                routerLink="/notifications" title="Notifiche"><img src="assets/img/dashboard/notification.png" height="45"
                    width="45"></a>


            <!-- <i class="fa fa-home font-large-1 cursor-pointer mr-2 text-info" routerLink=""></i> -->


            <!-- <button type="button" class="navbar-toggle d-lg-none float-left" data-toggle="collapse"
                (click)="toggleSidebar()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <span class="d-lg-none navbar-right navbar-collapse-toggle">
                <a class="open-navbar-container" (click)="isCollapsed = !isCollapsed"
                    [attr.aria-expanded]="!isCollapsed" aria-controls="navbarSupportedContent">
                    <i class="ft-more-vertical"></i>
                </a>
            </span> -->
            <span class="float:none;">
                <img src="assets/img/logos/logo-color-big.png" height="90">
                <!-- <img [src]="logoRivenditore" height="48" [title]="user.Licenza.NomeRivenditore"> -->
                <ng-container *ngIf="!user.Licenza.IsDemo">
                    Licenza <a routerLink="/license">{{user.Licenza.NomeLicenza}}</a> in collaborazione con

                    <img [src]="logoRivenditore" height="35" [title]="user.Licenza.NomeRivenditore">

                    <!-- <strong>{{user.Licenza.NomeRivenditore}}</strong> - Scade il
                    <strong>{{user.Licenza.DataScadenza | date}}</strong> -->
                </ng-container>
            </span>
            <!-- <form class="navbar-form navbar-right mt-1" role="search">
                <div class="position-relative has-icon-right">
                    <input type="text" class="form-control round" placeholder="Search">
                    <div class="form-control-position">
                        <i class="ft-search"></i>
                    </div>
                </div>
            </form> -->

        </div>
        <div class="navbar-container">
            <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
                <ul class="navbar-nav">
                    <!-- <li class="nav-item mr-2  d-none d-lg-block">
                        <a href="javascript:;" class="nav-link" id="navbar-fullscreen" appToggleFullscreen (click)="ToggleClass()">                            
                            <i class="{{toggleClass}} font-medium-3 blue-grey darken-4"></i>
                            <p class="d-none">fullscreen</p>
                        </a>
                    </li> -->

                    <!-- <li class="nav-item" ngbDropdown [placement]="placement">
                        <a class="nav-link position-relative" id="dropdownBasic2" ngbDropdownToggle>
                            <i class="ft-bell font-medium-3 blue-grey darken-4"></i>
                            <span class="notification badge badge-pill badge-danger">4</span>
                            <p class="d-none">Notifications</p>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="notification-dropdown">
                            <div class="noti-list" [perfectScrollbar]>
                                <a class="dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4">
                                    <i class="ft-bell info float-left d-block font-large-1 mt-1 mr-2"></i>
                                    <span class="noti-wrapper">
                                        <span class="noti-title line-height-1 d-block text-bold-400 info">New Order Received</span>
                                        <span class="noti-text">Lorem ipsum dolor sit ametitaque in, et!</span>
                                    </span>
                                </a>
                                <a class="dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4">
                                    <i class="ft-bell warning float-left d-block font-large-1 mt-1 mr-2"></i>
                                    <span class="noti-wrapper">
                                        <span class="noti-title line-height-1 d-block text-bold-400 warning">New User Registered</span>
                                        <span class="noti-text">Lorem ipsum dolor sit ametitaque in </span>
                                    </span>
                                </a>
                                <a class="dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4">
                                    <i class="ft-bell danger float-left d-block font-large-1 mt-1 mr-2"></i>
                                    <span class="noti-wrapper">
                                        <span class="noti-title line-height-1 d-block text-bold-400 danger">New Order Received</span>
                                        <span class="noti-text">Lorem ipsum dolor sit ametest?</span>
                                    </span>
                                </a>
                                <a class="dropdown-item noti-container py-3">
                                    <i class="ft-bell success float-left d-block font-large-1 mt-1 mr-2"></i>
                                    <span class="noti-wrapper">
                                        <span class="noti-title line-height-1 d-block text-bold-400 success">New User Registered</span>
                                        <span class="noti-text">Lorem ipsum dolor sit ametnatus aut.</span>
                                    </span>
                                </a>
                            </div>
                            <a class="noti-footer primary text-center d-block border-top border-top-blue-grey border-top-lighten-4 text-bold-400 py-1">Read All Notifications</a>
                        </div>
                    </li> -->
                    <li class="nav-item" ngbDropdown [placement]="placement">
                        <a class="nav-link position-relative" id="dropdownBasic3" ngbDropdownToggle>
                            <img class="img-responsive" [src]="logo" *ngIf="user.HasLogo" style="max-height: 75px;" />
                            <span style="font-weight: 600"> Benvenuto {{user.Email}}</span>
                            <p class="d-none">User Settings</p>
                        </a>
                        <div ngbDropdownMenu class="text-left">
                            <!-- <a class="dropdown-item py-1" routerLink="/settings">
                                <i class="ft-settings mr-2"></i>
                                <span>Impostazioni</span>
                            </a> -->
                            <!-- <a class="dropdown-item py-1" routerLink="/av0">
                                <i class="ft-file-text mr-2"></i>
                                <span>Autovalutazione Studio - Av0</span>
                            </a> -->
                            <a class="dropdown-item py-1" routerLink="/license">
                                <i class="ft-lock mr-2"></i>
                                <span>Licenza</span>
                            </a>
                            <!-- <a class="dropdown-item py-1" routerLink="/customize">
                                <i class="fa fa-paint-brush mr-2"></i>
                                <span>Temi</span>
                            </a> -->

                            <a class="dropdown-item" (click)="logout()">
                                <i class="ft-power mr-2"></i>
                                <span>Esci</span>
                            </a>
                            <!-- <a class="dropdown-item py-1" href="javascript:;">
                                <i class="ft-edit mr-2"></i>
                                <span>My Profile</span>
                            </a>
                            <a class="dropdown-item py-1" href="javascript:;">
                                <i class="ft-mail mr-2"></i>
                                <span>My Inbox</span>
                            </a>
                            <a class="dropdown-item py-1" href="javascript:;">
                                <i class="ft-settings mr-2"></i>
                                <span>Settings</span>
                            </a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:;">
                                <i class="ft-power mr-2"></i>
                                <span>Logout</span>
                            </a> -->
                        </div>
                    </li>
                    <!-- <li class="nav-item d-none d-lg-block">
                        <a class="nav-link position-relative notification-sidebar-toggle"
                            (click)="toggleNotificationSidebar();">
                            <i class="ft-align-left font-medium-3 blue-grey darken-4"></i>
                            <p class="d-none">Notifications Sidebar</p>
                        </a>
                    </li> -->

                </ul>
            </div>
        </div>
    </div>
</nav>
<!-- Navbar (Header) Ends -->