//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class DatiDocumentoDto {
	public TipoDocumento: string;
	public Numero: string;
	public RilasciatoDa: string;
	public DataRilascio: Date | null;
	public DataScadenza: Date | null;
	public DatiNascitaResidenzaComeDocumento: boolean;
}
