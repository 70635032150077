/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./expander.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./expander.component";
var styles_ExpanderComponent = [i0.styles];
var RenderType_ExpanderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExpanderComponent, data: {} });
export { RenderType_ExpanderComponent as RenderType_ExpanderComponent };
function View_ExpanderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "btn btn-link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "i", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "fa-chevron-circle-down": 0, "fa-chevron-circle-right": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.type, " fa"); var currVal_1 = _ck(_v, 4, 0, _co.isExpanded, !_co.isExpanded); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_ExpanderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), i1.ɵncd(null, 1)], null, null); }
export function View_ExpanderComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "h4", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "cursor": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExpanderComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExpanderComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 4, 0, (_co.canExpand ? "pointer" : "default")); _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.canExpand; _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.isExpanded; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "bs-callout bs-callout-", _co.type, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.type, " form-section"); _ck(_v, 1, 0, currVal_1); }); }
export function View_ExpanderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-expander", [], null, null, null, View_ExpanderComponent_0, RenderType_ExpanderComponent)), i1.ɵdid(1, 114688, null, 0, i3.ExpanderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExpanderComponentNgFactory = i1.ɵccf("app-expander", i3.ExpanderComponent, View_ExpanderComponent_Host_0, { isExpanded: "isExpanded", canExpand: "canExpand", type: "type" }, {}, ["[title]", "[body]"]);
export { ExpanderComponentNgFactory as ExpanderComponentNgFactory };
